export const services = [
  {
    id: 56,
    items: [
      { label: "Workowanie", value: "indywidualnie" },
      { label: "Kruszenie", value: "indywidualnie" },
      { label: "Dostawa do klienta", value: "indywidualnie" },
    ],
  },
  {
    id: 68,
    items: [
      { label: "Workowanie", value: "200zł/t" },
      { label: "Kruszenie", value: "200zł/t" },
      { label: "Dostawa do klienta", value: "100zł + 6,50zł/km" },
    ],
  },
  
  {
    id: 89,
    items: [
      { label: "Workowanie ekogroszku i miału", value: "150zł/t" },
      { label: "Workowanie orzechów", value: "200zł/t" },
      { label: "Workowanie kostki", value: "250zł/t" },
      { label: "Transport do 5km", value: "150zł"},
      { label: "Transport do 10km", value: "200zł"},
      { label: "Transport do 15km", value: "250zł"},
      { label: "Transport do 20km", value: "300zł"},
      { label: "Transport do 25km", value: "350zł"},
      { label: "Transport do 30km", value: "400zł"},
      { label: "Transport do 40km", value: "450zł"},
      { label: "Transport do 50km", value: "500zł"},
      { label: "Transport powyżej 50 km", value: "indywidualnie" },
    ],
  },
  {
    id: 27,
    items: [
      { label: "Workowanie", value: "200zł/t" },
      { label: "Dostawa do klienta", value: "7,00zł/km" },
    ],
  },
  {
    id: 44,
    items: [{ label: "Dostawa do klienta", value: "7,00zł/km" }],
  },
  {
    id: 5,
    items: [{ label: "Dostawa do klienta", value: "4,00zł/km" }],
  },
  {
    id: 39,
    items: [
      { label: "Workowanie", value: "200zł/t" },
      { label: "Dostawa do klienta", value: "6,00zł/km" },
    ],
  },
  {
    id: 47,
    items: [
      { label: "Workowanie", value: "150zł/t" },
      { label: "Dostawa do klienta", value: "5,00zł/km" },
    ],
  },
  {
    id: 65,
    items: [{ label: "Dostawa do klienta", value: "6,00zł/km" }],
  },
  {
    id: 3,
    items: [{ label: "Dostawa do klienta", value: "8,00zł/km" }],
  },
  {
    id: 10,
    items: [
      { label: "Workowanie", value: "200zł/t" },
      { label: "Dostawa do klienta", value: "6,00zł/km" },
    ],
  },
  {
    id: 35,
    items: [
      { label: "Workowanie", value: "170zł/t" },
      { label: "Dostawa do klienta", value: "5,00zł/km" },
    ],
  },
  {
    id: 11,
    items: [{ label: "Workowanie", value: "80zł/t" }],
  },
  {
    id: 46,
    items: [
      { label: "Workowanie", value: "190zł/t" },
      { label: "Dostawa do klienta", value: "8,90zł/km" },
    ],
  },
  {
    id: 6,
    items: [
      { label: "Workowanie", value: "150zł/t" },
      { label: "Dostawa do klienta", value: "5,00zł/km" },
    ],
  },
  {
    id: 42,
    items: [{ label: "Kruszenie", value: "100zł/t" }],
  },
  {
    id: 73,
    items: [
      { label: "Kruszenie (bez kostki)", value: "200zł/t" },
      { label: "Workowanie", value: "160zł/t" },
      { label: "Dostawa do klienta", value: "7,38zł/km" },

    ],
  },
  {
    id: 21,
    items: [
      { label: "Kruszenie Orzech I/II", value: "209,10zł/t" },
      { label: "Kruszenie Groszek", value: "184,50zł/t" },
      { label: "Workowanie 25kg", value: "295,20zł/t" },
      { label: "Workowanie 10kg", value: "356,70zł/t" },
      { label: "Pakowanie BigBag", value: "159,90zł/t" },
      { label: "Pakowanie BigBag + spinanie taśmą", value: "184,50zł/t" },
      { label: "Transport do 10km", value: "184,50zł/t" },
      { label: "Transport do 30km", value: "246zł/t" },
      { label: "Transport do 50km", value: "307,50zł/t" },
      { label: "Transport workowanego do 10km", value: "121,77zł/t" },
      { label: "Transport workowanego do 30km", value: "184,50zł/t" },
      { label: "Transport workowanego do 50km", value: "246zł/t" },
      { label: "Transport powyżej 50 km", value: "indywidualnie" },
      { label: "Wysyłka kurierem Poczty Polskiej lub PEKAS", value: "306,27zł/t" },
    ],
  },
  {
    id: 16,
    items: [{ label: "Dostawa do klienta", value: "4,00zł/km" }],
  },
  {
    id: 51,
    items: [
      { label: "Workowanie 25kg", value: "200zł/t" },
      { label: "Transport do 15km", value: "gratis" },
      { label: "Transport powyżej 15 km", value: "indywidualnie" },
    ],
  },
  {
    id: 77,
    items: [
      { label: "Kruszenie", value: "200zł/t" },
      { label: "Workowanie 25kg", value: "150zł/t" },
      { label: "Pakowanie BigBag", value: "100zł/t" },
      { label: "Transport do 10km", value: "150zł/t" },
      { label: "Transport do 30km", value: "350zł/t" },
      { label: "Transport do 50km", value: "550zł/t" },
      { label: "Transport workowanego do 10km", value: "99zł/t" },
      { label: "Transport workowanego do 30km", value: "150zł/t" },
      { label: "Transport workowanego do 50km", value: "200zł/t" },
      { label: "Transport powyżej 50 km", value: "indywidualnie" },
    ],
  },
  {
    id: 83,
    items: [
      { label: "Workowanie 25kg", value: "200zł/t" },,
      { label: "Transport do 15km", value: "100zł/t" },
      { label: "Transport do 30km", value: "150zł/t" },
      { label: "Transport do 50km", value: "200zł/t" },
      { label: "Transport do 75km", value: "250zł/t" },
      { label: "Transport powyżej 75 km", value: "we własnym zakresie" },
      { label: "Wysyłka kurierem Poczty Polskiej", value: "300zł/t" },
    ],
  },
  {
    id: 72,
    items: [
      { label: "Kruszenie Orzech I/II / Groszek / Kostka", value: "250zł/t" },
      { label: "Workowanie 25kg", value: "250zł/t" },
      { label: "Pakowanie BigBag", value: "200zł/t" },
      { label: "Transport", value: "indywidualnie" },
    ],
  },
  {
    id: 60,
    items: [
      { label: "Pakowanie BigBag + spinanie taśmą", value: "150zł/t" },
      { label: "Transport węgla luzem", value: "10zł/t" },
      { label: "Transport węgla BigBag", value: "10zł/t" },
    ],
  },
  {
    id: 43,
    items: [
      { label: "Transport do 13km", value: "200zł" },
      { label: "Transport powyżej 13km", value: "8zł/km" },
    ],
  },
  {
    id: 39,
    items: [
      { label: "Workowanie", value: "200zł/t" },
      { label: "Dostawa do klienta", value: "6,00zł/km" },
    ],
  },
  {
    id: 29,
    items: [
      { label: "Workowanie", value: "180zł/t" },
      { label: "Dostawa do klienta", value: "8,00zł/km" },
    ],
  },
  {
    id: 38,
    items: [
      { label: "Workowanie + kaucja za paletę(50zł/szt)", value: "150zł/t" },
      { label: "Pakowanie BigBag", value: "150zł/t" },
      { label: "Wysyłka kurierem", valuee: "275zł/t" },
      { label: "Transport do 8km", value: "100km" },
      { label: "Dostawa do klienta", value: "6,00zł/km" },
    ],
  },
  {
    id: 37,
    items: [
      { label: "Workowanie", value: "240zł/t" },
      { label: "Pakowanie BigBag", value: "130zł/t" },
      { label: "Transport 20km", valuee: "35zł/t" },
      { label: "Transport do 30km", value: "55zł/t" },
      { label: "Transport do 40km", value: "70zł/t" },
      { label: "Transport powyżej 40km", value: "5,00zł/km" },
    ],
  },
  {
    id: 18,
    items: [
      { label: "Dostawa do klienta", value: "5,00zł/km" },
    ],
  },
  {
    id: 90,
    items: [
      { label: "Workowanie", value: "200zł/t" },
      { label: "Dostawa do klienta do 50km", value: "3,50zł/km" },
    ],
  },
];
