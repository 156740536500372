export const kdwList = {
  "dolnośląskie": [
      {
          "id": 78,
          "nazwa": "PHU ALANEX",
          "kod_pocztowy": "58-370",
          "miejscowosc": "Boguszów-Gorce",
          "ulica": "Dworcowa",
          "nr_domu": "6",
          "wojewodztwo_id": "1",
          "telefon": "748449997",
          "email": "alanex@interia.eu",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00-15:00",
          "godziny_otwarcia_sobota": "07:00-13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "ząbkowicki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "kłodzki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "strzeliński",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "dzierżoniowski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "wałbrzyski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "Wałbrzych",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 84,
              "cena_obslugi_tona": "241.59999999999999",
              "created_at": "2022-09-27 09:51:20.447",
              "updated_at": "2022-09-27 09:51:20.447"
          }
      },
      {
          "id": 63,
          "nazwa": "SOMBIN Stanisław Tyrała Spółka Jawna",
          "kod_pocztowy": "58-500",
          "miejscowosc": "Jelenia Góra",
          "ulica": "Łomnicka",
          "nr_domu": "0",
          "wojewodztwo_id": "1",
          "telefon": "757524091",
          "email": "sklad.jelenia@sombin.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 -.15:00",
          "godziny_otwarcia_sobota": "08:00 -12:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "jaworski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "złotoryjski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "kamiennogórski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "Jelenia Góra",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 161,
              "cena_obslugi_tona": "368.05000000000001",
              "created_at": "2023-02-20 08:24:06.977",
              "updated_at": "2023-02-20 08:24:06.977"
          }
      },
      {
          "id": 36,
          "nazwa": "Firma Usługowo-Handlowa. Export-Import. Mariusz Ral",
          "kod_pocztowy": "59-800",
          "miejscowosc": "Lubań",
          "ulica": "Pisarzowice",
          "nr_domu": "275D",
          "wojewodztwo_id": "1",
          "telefon": "728280090",
          "email": "mariusz_ral@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 16:00",
          "godziny_otwarcia_sobota": "08:00 - 14:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "lubański",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "lwówecki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "zgorzelecki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "bolesławiecki",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 48,
              "cena_obslugi_tona": "304.88",
              "created_at": "2022-09-15 12:52:02.033",
              "updated_at": "2022-09-15 12:52:02.033"
          }
      },
      {
          "id": 50,
          "nazwa": "PPHU LIBERA Joanna Skoczylas",
          "kod_pocztowy": "56-400",
          "miejscowosc": "Oleśnica",
          "ulica": "Wrocławska",
          "nr_domu": "42",
          "wojewodztwo_id": "1",
          "telefon": "662365568",
          "email": "phulibera.kdw@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "09:00 -17:00",
          "godziny_otwarcia_sobota": "08:00 -13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "milicki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "trzebnicki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "oleśnicki",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 64,
              "cena_obslugi_tona": "293.27999999999997",
              "created_at": "2022-09-20 11:28:14.590",
              "updated_at": "2022-09-20 11:28:14.590"
          }
      },
      {
          "id": 56,
          "nazwa": "FIRMA HANDLOWO USŁUGOWA GRZYBOWSKI",
          "kod_pocztowy": "58-130",
          "miejscowosc": "Imbramowice",
          "ulica": "Boczna",
          "nr_domu": "17",
          "wojewodztwo_id": "1",
          "telefon": "786445939",
          "email": "fhugrzybowskiadam@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8.00 - 16.00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "świdnicki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "wrocławski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "średzki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "oławski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "Wrocław",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 67,
              "cena_obslugi_tona": "187.61000000000001",
              "created_at": "2022-09-20 12:05:54.167",
              "updated_at": "2022-09-20 12:05:54.167"
          }
      },
      {
          "id": 84,
          "nazwa": "PROMOTOR Materiały Budowlane Robert Adamczak",
          "kod_pocztowy": "56-100",
          "miejscowosc": "Wołów",
          "ulica": "Witosa",
          "nr_domu": "1",
          "wojewodztwo_id": "1",
          "telefon": "782877773",
          "email": "robert@promotorsc.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 -17:00",
          "godziny_otwarcia_sobota": "nieczynne",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "wołowski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "legnicki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "górowski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "głogowski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "polkowicki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "lubiński",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 91,
              "cena_obslugi_tona": "181.19999999999999",
              "created_at": "2022-10-03 15:38:59.587",
              "updated_at": "2022-10-03 15:38:59.587"
          }
      },
      {
          "id": 120,
          "nazwa": "\"ANTRACYT\" Sprzedaż Opału Marek Warowy",
          "kod_pocztowy": "57-400",
          "miejscowosc": "Nowa Ruda",
          "ulica": "Kolejowa",
          "nr_domu": "28",
          "wojewodztwo_id": "1",
          "telefon": "748725292",
          "email": "mwarowy@onet.eu",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00-17:00",
          "godziny_otwarcia_sobota": "07:00-14:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "ząbkowicki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "kłodzki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "strzeliński",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "dzierżoniowski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "wałbrzyski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "Wałbrzych",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 127,
              "cena_obslugi_tona": "241.59999999999999",
              "created_at": "2022-11-03 08:48:50.937",
              "updated_at": "2022-11-03 08:48:50.937"
          }
      },
      {
          "id": 119,
          "nazwa": "Przedsiębiorstwo Handlowo-Usługowe \"Art-Zbyt\"",
          "kod_pocztowy": "56-300",
          "miejscowosc": "Milicz",
          "ulica": "Trzebnicka",
          "nr_domu": "31",
          "wojewodztwo_id": "1",
          "telefon": "601443569",
          "email": "kdw@artzbyt.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00-16:00",
          "godziny_otwarcia_sobota": "8:00-14:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "milicki",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 125,
              "cena_obslugi_tona": "293.27999999999997",
              "created_at": "2022-11-02 10:21:11.000",
              "updated_at": "2022-11-02 10:21:11.000"
          }
      },
      {
          "id": 129,
          "nazwa": "Przedsiębiorstwo Wielobranżowe \"RUPEX \" S.J. Renata Płońska, Dariusz Płoński",
          "kod_pocztowy": "58-400",
          "miejscowosc": "Kamienna Góra",
          "ulica": "Magazynowa",
          "nr_domu": "4",
          "wojewodztwo_id": "1",
          "telefon": "604489844",
          "email": "rupex2@rupex2.com",
          "geo_n": "50.782918000000002",
          "geo_e": "16.013400499999999",
          "godziny_otwarcia": "7:00 - 15:00",
          "godziny_otwarcia_sobota": "-",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "kamiennogórski",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 172,
              "cena_obslugi_tona": "355.08999999999997",
              "created_at": "2023-02-23 07:21:18.170",
              "updated_at": "2023-02-23 07:21:18.170"
          }
      },
      {
          "id": 125,
          "nazwa": "Z.U.H. \"MAX\" Jerzy Maziarka",
          "kod_pocztowy": "67-200",
          "miejscowosc": "Głogów",
          "ulica": "Rudnowska",
          "nr_domu": "58",
          "wojewodztwo_id": "1",
          "telefon": "768353867",
          "email": "biuro@maxglogow.pl",
          "geo_n": "51.655688300000001",
          "geo_e": "16.1185714",
          "godziny_otwarcia": "08:00 - 16:00",
          "godziny_otwarcia_sobota": "Nieczynne",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "głogowski",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 132,
              "cena_obslugi_tona": "181.19999999999999",
              "created_at": "2022-11-09 08:38:42.977",
              "updated_at": "2022-11-09 08:38:42.977"
          }
      },
      {
          "id": 130,
          "nazwa": "PPHU M. Szczepański Sp.j",
          "kod_pocztowy": "55-230",
          "miejscowosc": "Jelcz-Laskowice",
          "ulica": "Inżynierska",
          "nr_domu": "1",
          "wojewodztwo_id": "1",
          "telefon": "713811204",
          "email": "jelcz@sklad-szczepanski.pl",
          "geo_n": "51.037259599999999",
          "geo_e": "17.314186800000002",
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": "8:00 - 13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "świdnicki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "wrocławski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "średzki",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "oławski",
                  "wojewodztwo": "dolnośląskie"
              },
              {
                  "powiat": "Wrocław",
                  "wojewodztwo": "dolnośląskie"
              }
          ],
          "wojewodztwo": {
              "id": 1,
              "nazwa": "dolnośląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 137,
              "cena_obslugi_tona": "187.61000000000001",
              "created_at": "2022-11-15 09:02:45.490",
              "updated_at": "2022-11-15 09:02:45.490"
          }
      }
  ],
  "kujawsko-pomorskie": [
      {
          "id": 81,
          "nazwa": "Handel Artykułami Masowymi i Transport Krystian Onka",
          "kod_pocztowy": "87-610",
          "miejscowosc": "Dobrzyń nadWisłą",
          "ulica": "Szpiegowo",
          "nr_domu": "29",
          "wojewodztwo_id": "2",
          "telefon": "542541710",
          "email": "k.onka@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:30 - 15:30",
          "godziny_otwarcia_sobota": "08:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "włocławski",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "lipnowski",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "Włocławek",
                  "wojewodztwo": "kujawsko-pomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 2,
              "nazwa": "kujawsko-pomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 88,
              "cena_obslugi_tona": "428.95999999999998",
              "created_at": "2022-09-29 09:37:15.433",
              "updated_at": "2022-09-29 09:37:15.433"
          }
      },
      {
          "id": 51,
          "nazwa": "KAPOST Sp. z o.o.",
          "kod_pocztowy": "89-400",
          "miejscowosc": "Sępólno Krajeńskie",
          "ulica": "Składowa",
          "nr_domu": "2",
          "wojewodztwo_id": "2",
          "telefon": "523076367",
          "email": "kapost.baza@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7.00 - 15.00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "tucholski",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "sępoleński",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "bydgoski",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "nakielski",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "Bydgoszcz",
                  "wojewodztwo": "kujawsko-pomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 2,
              "nazwa": "kujawsko-pomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 65,
              "cena_obslugi_tona": "491.89999999999998",
              "created_at": "2022-09-20 11:47:49.920",
              "updated_at": "2022-09-20 11:47:49.920"
          }
      },
      {
          "id": 90,
          "nazwa": "WARPOL SERVICE",
          "kod_pocztowy": "88-200",
          "miejscowosc": "Radziejów",
          "ulica": "Przemysłowa",
          "nr_domu": "10A",
          "wojewodztwo_id": "2",
          "telefon": "883775366",
          "email": "biuro@warpolservice.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:30-15:30",
          "godziny_otwarcia_sobota": "08:00 -12:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "żniński",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "aleksandrowski",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "radziejowski",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "inowrocławski",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "mogileński",
                  "wojewodztwo": "kujawsko-pomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 2,
              "nazwa": "kujawsko-pomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 98,
              "cena_obslugi_tona": "556.63999999999999",
              "created_at": "2022-10-06 13:20:32.907",
              "updated_at": "2022-10-06 13:20:32.907"
          }
      },
      {
          "id": 96,
          "nazwa": "Centrala Nasienna Anna Wiśniewska",
          "kod_pocztowy": "87-300",
          "miejscowosc": "Brodnica",
          "ulica": "Świerkowa",
          "nr_domu": "3",
          "wojewodztwo_id": "2",
          "telefon": "564983287",
          "email": "a1wisniewska@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 -16:00",
          "godziny_otwarcia_sobota": "08:00 - 13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "golubsko-dobrzyński",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "brodnicki",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "rypiński",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "wąbrzeski",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "toruński",
                  "wojewodztwo": "kujawsko-pomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 2,
              "nazwa": "kujawsko-pomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 103,
              "cena_obslugi_tona": "452.63999999999999",
              "created_at": "2022-10-07 14:21:29.053",
              "updated_at": "2022-10-07 14:21:29.053"
          }
      },
      {
          "id": 95,
          "nazwa": "Skład Towarowy \"Skonto\" Zbigniew Pacek",
          "kod_pocztowy": "86-120",
          "miejscowosc": "Pruszcz",
          "ulica": "Wałdowo",
          "nr_domu": "20",
          "wojewodztwo_id": "2",
          "telefon": "731009914",
          "email": "cowaldowo@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": "08:00-13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "grudziądzki",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "świecki",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "chełmiński",
                  "wojewodztwo": "kujawsko-pomorskie"
              },
              {
                  "powiat": "Grudziądz",
                  "wojewodztwo": "kujawsko-pomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 2,
              "nazwa": "kujawsko-pomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 102,
              "cena_obslugi_tona": "362.85000000000002",
              "created_at": "2022-10-07 07:44:01.567",
              "updated_at": "2022-10-07 07:44:01.567"
          }
      }
  ],
  "lubelskie": [
      {
          "id": 44,
          "nazwa": "FAST-CHEM Stanisław Fabrowski",
          "kod_pocztowy": "22-530",
          "miejscowosc": "Mircze",
          "ulica": "Kryłowska",
          "nr_domu": "10",
          "wojewodztwo_id": "3",
          "telefon": "846519216",
          "email": "stanislaw@fast-chem.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 15:00",
          "godziny_otwarcia_sobota": "07:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "biłgorajski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "zamojski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "hrubieszowski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "tomaszowski",
                  "wojewodztwo": "lubelskie"
              }
          ],
          "wojewodztwo": {
              "id": 3,
              "nazwa": "lubelskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 56,
              "cena_obslugi_tona": "245.5",
              "created_at": "2022-09-17 13:06:19.597",
              "updated_at": "2022-09-17 13:06:19.597"
          }
      },
      {
          "id": 54,
          "nazwa": "Przedsiębiorstwo Handlowo Usługowe Tadeusz Szymajda",
          "kod_pocztowy": "08-530",
          "miejscowosc": "Dęblin",
          "ulica": "Towarowa",
          "nr_domu": "4",
          "wojewodztwo_id": "3",
          "telefon": "795589739",
          "email": "tadeuszszymajda@op.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "lubartowski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "łukowski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "puławski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "radzyński",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "rycki",
                  "wojewodztwo": "lubelskie"
              }
          ],
          "wojewodztwo": {
              "id": 3,
              "nazwa": "lubelskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 66,
              "cena_obslugi_tona": "241.5",
              "created_at": "2022-09-20 12:03:51.333",
              "updated_at": "2022-09-20 12:03:51.333"
          }
      },
      {
          "id": 17,
          "nazwa": "Przedsiębiorstwo Wielobranżowe Usługi- Handel \"FURT-POL\" Piotr Furtak",
          "kod_pocztowy": "24-310",
          "miejscowosc": "Karczmiska",
          "ulica": "Opolska",
          "nr_domu": "16",
          "wojewodztwo_id": "3",
          "telefon": "532770800",
          "email": "furt-pol@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8-16",
          "godziny_otwarcia_sobota": "8-13",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "lubelski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "opolski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "kraśnicki",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "janowski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "Lublin",
                  "wojewodztwo": "lubelskie"
              }
          ],
          "wojewodztwo": {
              "id": 3,
              "nazwa": "lubelskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 28,
              "cena_obslugi_tona": "270.85000000000002",
              "created_at": "2022-09-06 07:38:43.617",
              "updated_at": "2022-09-06 07:38:43.617"
          }
      },
      {
          "id": 41,
          "nazwa": "SOBIANEK Sp. z o.o.",
          "kod_pocztowy": "21-210",
          "miejscowosc": "Milanów",
          "ulica": "Rudno III",
          "nr_domu": "15",
          "wojewodztwo_id": "3",
          "telefon": "519130205",
          "email": "mariola.wolowik@sobianek.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 16:00",
          "godziny_otwarcia_sobota": "Nieczynne",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "włodawski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "bialski",
                  "wojewodztwo": "lubelskie"
              },
              {
                  "powiat": "parczewski",
                  "wojewodztwo": "lubelskie"
              }
          ],
          "wojewodztwo": {
              "id": 3,
              "nazwa": "lubelskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 54,
              "cena_obslugi_tona": "336.5",
              "created_at": "2022-09-16 06:57:26.900",
              "updated_at": "2022-09-16 06:57:26.900"
          }
      }
  ],
  "lubuskie": [
      {
          "id": 97,
          "nazwa": "Andrzej Wdowiak Skład Opału",
          "kod_pocztowy": "63-300",
          "miejscowosc": "Międzyrzecz",
          "ulica": "Plac Powstańców Wielkopolskich",
          "nr_domu": "3 A",
          "wojewodztwo_id": "4",
          "telefon": "535066096",
          "email": "andrzej_wdowiak@poczta.onet.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00-17:00",
          "godziny_otwarcia_sobota": "08:00-13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "sulęciński",
                  "wojewodztwo": "lubuskie"
              },
              {
                  "powiat": "gorzowski",
                  "wojewodztwo": "lubuskie"
              },
              {
                  "powiat": "strzelecko-drezdenecki",
                  "wojewodztwo": "lubuskie"
              },
              {
                  "powiat": "słubicki",
                  "wojewodztwo": "lubuskie"
              },
              {
                  "powiat": "międzyrzecki",
                  "wojewodztwo": "lubuskie"
              },
              {
                  "powiat": "świebodziński",
                  "wojewodztwo": "lubuskie"
              }
          ],
          "wojewodztwo": {
              "id": 4,
              "nazwa": "lubuskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 104,
              "cena_obslugi_tona": "336.58999999999997",
              "created_at": "2022-10-11 11:33:20.557",
              "updated_at": "2022-10-11 11:33:20.557"
          }
      }
  ],
  "mazowieckie": [
      {
          "id": 67,
          "nazwa": "Skłodowski Spółka Jawna",
          "kod_pocztowy": "07-323",
          "miejscowosc": "Zaręby Kościelne",
          "ulica": "Czyżewska",
          "nr_domu": "20",
          "wojewodztwo_id": "7",
          "telefon": "668682101",
          "email": "l.zaremba@sklodowski.com.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 16:00",
          "godziny_otwarcia_sobota": "08:00 - 15:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "węgrowski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "sokołowski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "ostrowski",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 74,
              "cena_obslugi_tona": "363.43000000000001",
              "created_at": "2022-09-21 08:42:31.740",
              "updated_at": "2022-09-21 08:42:31.740"
          }
      },
      {
          "id": 10,
          "nazwa": "Przedsiębiorstwo Wielobranżowe „AMSON” Rapczewski Marcin Bartosz",
          "kod_pocztowy": "05-402",
          "miejscowosc": "Otwock",
          "ulica": "Józefa Ignacego Kraszewskiego",
          "nr_domu": "3",
          "wojewodztwo_id": "7",
          "telefon": "729806833",
          "email": "biuro@amson.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8-16",
          "godziny_otwarcia_sobota": "8-12",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "miński",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "piaseczyński",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "otwocki",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 163,
              "cena_obslugi_tona": "387.88",
              "created_at": "2023-02-20 10:36:57.737",
              "updated_at": "2023-02-20 10:36:57.737"
          }
      },
      {
          "id": 18,
          "nazwa": "STU Płock Sp. z o.o.",
          "kod_pocztowy": "09-451",
          "miejscowosc": "Radzanowo",
          "ulica": "Spółdzielcza",
          "nr_domu": "7",
          "wojewodztwo_id": "7",
          "telefon": "606628735",
          "email": "br.stu@op.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 15:00",
          "godziny_otwarcia_sobota": "07:00 -15:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "sochaczewski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "gostyniński",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "płocki",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 29,
              "cena_obslugi_tona": "500.13999999999999",
              "created_at": "2022-09-06 08:05:02.727",
              "updated_at": "2022-09-06 08:05:02.727"
          }
      },
      {
          "id": 33,
          "nazwa": "„MAR – KOKS” Krasowski, Szewczyk Sp. J.",
          "kod_pocztowy": "05-220",
          "miejscowosc": "Zielonka",
          "ulica": "Krzywa",
          "nr_domu": "22",
          "wojewodztwo_id": "7",
          "telefon": "601689704",
          "email": "mar-koks@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "od 8 do 16",
          "godziny_otwarcia_sobota": "od 8:00 do13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "wyszkowski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "nowodworski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "wołomiński",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "legionowski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "pułtuski",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 44,
              "cena_obslugi_tona": "280.10000000000002",
              "created_at": "2022-09-13 10:49:19.353",
              "updated_at": "2022-09-13 10:49:19.353"
          }
      },
      {
          "id": 30,
          "nazwa": "BIMAR Marta Bicz",
          "kod_pocztowy": "26-700",
          "miejscowosc": "Zwoleń",
          "ulica": "Batalionów Chłopskich",
          "nr_domu": "5",
          "wojewodztwo_id": "7",
          "telefon": "505387714",
          "email": "sprzedaz@bimar.net.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:00 - 16:00",
          "godziny_otwarcia_sobota": "7:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "lipski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "radomski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "szydłowiecki",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "zwoleński",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "przysuski",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 40,
              "cena_obslugi_tona": "184.62",
              "created_at": "2022-09-12 08:29:23.247",
              "updated_at": "2022-09-12 08:29:23.247"
          }
      },
      {
          "id": 23,
          "nazwa": "Przedsiębiorstwo Handlowo - Usługowe Anna i Janusz Bugajewscy",
          "kod_pocztowy": "09-100",
          "miejscowosc": "Płońsk",
          "ulica": "Wyszogrodzka",
          "nr_domu": "60",
          "wojewodztwo_id": "7",
          "telefon": "236625522",
          "email": "phubugajewscy@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00-15:00",
          "godziny_otwarcia_sobota": "nieczynne",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "mławski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "płoński",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "sierpecki",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "żuromiński",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "ciechanowski",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 34,
              "cena_obslugi_tona": "377.24000000000001",
              "created_at": "2022-09-08 10:36:26.403",
              "updated_at": "2022-09-08 10:36:26.403"
          }
      },
      {
          "id": 79,
          "nazwa": "Przedsiębiorstwo Handlowo-Produkcyjno-Usługowe\"BARTCZAK\"Sp.zo.o.",
          "kod_pocztowy": "05-870",
          "miejscowosc": "Błonie",
          "ulica": "Kolejowa",
          "nr_domu": "9",
          "wojewodztwo_id": "7",
          "telefon": "668646944",
          "email": "info@bartczak.com.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": "nieczynne",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "żyrardowski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "grodziski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "warszawski zachodni",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "pruszkowski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "Warszawa",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 86,
              "cena_obslugi_tona": "487.51999999999998",
              "created_at": "2022-09-27 10:35:32.070",
              "updated_at": "2022-09-27 10:35:32.070"
          }
      },
      {
          "id": 86,
          "nazwa": "Firma Usługowo Handlowa ROLMAK Marzena Rzedzicka",
          "kod_pocztowy": "08-455",
          "miejscowosc": "Trojanów",
          "ulica": "Korytnica",
          "nr_domu": "82 A",
          "wojewodztwo_id": "7",
          "telefon": "538656105",
          "email": "rolmak82a@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 17:00",
          "godziny_otwarcia_sobota": "08:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": null,
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "białobrzeski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "kozienicki",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "grójecki",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "garwoliński",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 93,
              "cena_obslugi_tona": "196.80000000000001",
              "created_at": "2022-10-03 15:52:40.297",
              "updated_at": "2022-10-03 15:52:40.297"
          }
      },
      {
          "id": 85,
          "nazwa": "AGRONECO PLUS Sp. z o.o.",
          "kod_pocztowy": "08-106",
          "miejscowosc": "Borki-Kosy",
          "ulica": "Borki-Kosy",
          "nr_domu": "72a",
          "wojewodztwo_id": "7",
          "telefon": "661573130",
          "email": "naszezboza@o2.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08.00-16.00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "siedlecki",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "łosicki",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 92,
              "cena_obslugi_tona": "429.61000000000001",
              "created_at": "2022-10-03 15:47:20.977",
              "updated_at": "2022-10-03 15:47:20.977"
          }
      },
      {
          "id": 94,
          "nazwa": "Krzysztof Ustaszewski Usługi Transportowe",
          "kod_pocztowy": "07-401",
          "miejscowosc": "Ostrołęka",
          "ulica": "Kołobrzeska",
          "nr_domu": "6",
          "wojewodztwo_id": "7",
          "telefon": "693317000",
          "email": "k.ustaszewski@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:30-17:00",
          "godziny_otwarcia_sobota": "08:00-14:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "ostrołęcki",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "makowski",
                  "wojewodztwo": "mazowieckie"
              },
              {
                  "powiat": "przasnyski",
                  "wojewodztwo": "mazowieckie"
              }
          ],
          "wojewodztwo": {
              "id": 7,
              "nazwa": "mazowieckie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 101,
              "cena_obslugi_tona": "507.25",
              "created_at": "2022-10-06 14:19:58.540",
              "updated_at": "2022-10-06 14:19:58.540"
          }
      }
  ],
  "małopolskie": [
      {
          "id": 60,
          "nazwa": "GS Bukowina Tatrzańska Sp. z o.o.",
          "kod_pocztowy": "34-530",
          "miejscowosc": "Bukowina Tatrzańska",
          "ulica": "Kościuszki",
          "nr_domu": "36",
          "wojewodztwo_id": "6",
          "telefon": "519063090",
          "email": "kdw@gstatry.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 -17:00",
          "godziny_otwarcia_sobota": "07:00 -15:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "nowotarski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "tatrzański",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 69,
              "cena_obslugi_tona": "123.54000000000001",
              "created_at": "2022-09-20 12:25:32.683",
              "updated_at": "2022-09-20 12:25:32.683"
          }
      },
      {
          "id": 4,
          "nazwa": "Firma Handlowo Usługowa \"ADMET\" Adam i Teresa Czekaj",
          "kod_pocztowy": "32-100",
          "miejscowosc": "Proszowice",
          "ulica": "Kościelec",
          "nr_domu": "19 A",
          "wojewodztwo_id": "6",
          "telefon": "501594849",
          "email": "admet2@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7.30-16.00",
          "godziny_otwarcia_sobota": "7.30-13.00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "miechowski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "proszowicki",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 157,
              "cena_obslugi_tona": "249.09",
              "created_at": "2023-02-16 08:32:33.793",
              "updated_at": "2023-02-16 08:32:33.793"
          }
      },
      {
          "id": 46,
          "nazwa": "NIWA Stanisław Głuc Spółka Jawna",
          "kod_pocztowy": "34-234",
          "miejscowosc": "Osielec",
          "ulica": "Osielec",
          "nr_domu": "777",
          "wojewodztwo_id": "6",
          "telefon": "509385986",
          "email": "biuro@niwadom.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:00-17:00",
          "godziny_otwarcia_sobota": "7:00-13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "wadowicki",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "oświęcimski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "suski",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 58,
              "cena_obslugi_tona": "161.62",
              "created_at": "2022-09-19 11:11:12.437",
              "updated_at": "2022-09-19 11:11:12.437"
          }
      },
      {
          "id": 75,
          "nazwa": "Przedsiębiorstwo Handlowo - Usługowe „SYLMET” Sylwia Wróbel",
          "kod_pocztowy": "32-566",
          "miejscowosc": "Alwernia",
          "ulica": "Fabryczna",
          "nr_domu": "11",
          "wojewodztwo_id": "6",
          "telefon": "500097107",
          "email": "sylmet.pl@interia.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "chrzanowski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "olkuski",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 82,
              "cena_obslugi_tona": "305.61000000000001",
              "created_at": "2022-09-27 07:00:41.267",
              "updated_at": "2022-09-27 07:00:41.267"
          }
      },
      {
          "id": 9,
          "nazwa": "BOSTAR Sp. z o.o.",
          "kod_pocztowy": "32-067",
          "miejscowosc": "Tenczynek",
          "ulica": "Wcisły",
          "nr_domu": "1",
          "wojewodztwo_id": "6",
          "telefon": "536760730",
          "email": "sklad.tenczynek@bostar.com.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "od 7:00 do 15:00",
          "godziny_otwarcia_sobota": "nieczynne",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "krakowski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "Kraków",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 162,
              "cena_obslugi_tona": "189.59999999999999",
              "created_at": "2023-02-20 08:30:49.667",
              "updated_at": "2023-02-20 08:30:49.667"
          }
      },
      {
          "id": 21,
          "nazwa": "KGM WĘGIEL Nikodem Klimowski",
          "kod_pocztowy": "32-433",
          "miejscowosc": "Lubień",
          "ulica": "Lubień",
          "nr_domu": "1123",
          "wojewodztwo_id": "6",
          "telefon": "572020080",
          "email": "kontakt@skladkdwpgg.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8.00 - 16.00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "limanowski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "myślenicki",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 32,
              "cena_obslugi_tona": "104.54000000000001",
              "created_at": "2022-09-07 12:18:56.877",
              "updated_at": "2022-09-07 12:18:56.877"
          }
      },
      {
          "id": 2,
          "nazwa": "CHEMPAK Marek Więckowski",
          "kod_pocztowy": "32-800",
          "miejscowosc": "Brzesko",
          "ulica": "Kopernika",
          "nr_domu": "18 C",
          "wojewodztwo_id": "6",
          "telefon": "146863881",
          "email": "biuro@chempak.com.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00-15:00",
          "godziny_otwarcia_sobota": "08:00-13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "bocheński",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "brzeski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "wielicki",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 156,
              "cena_obslugi_tona": "253.66",
              "created_at": "2023-02-16 07:05:48.793",
              "updated_at": "2023-02-16 07:05:48.793"
          }
      },
      {
          "id": 19,
          "nazwa": "F.H. Glifada Tarnów Robert Bieda",
          "kod_pocztowy": "32-240",
          "miejscowosc": "Żabno",
          "ulica": "Piłsudskiego",
          "nr_domu": "69",
          "wojewodztwo_id": "6",
          "telefon": "668618094",
          "email": "lukasz.glifada@interia.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 15:00",
          "godziny_otwarcia_sobota": "07:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "dąbrowski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "tarnowski",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 155,
              "cena_obslugi_tona": "278.06999999999999",
              "created_at": "2023-02-15 11:52:17.627",
              "updated_at": "2023-02-15 11:52:17.627"
          }
      },
      {
          "id": 80,
          "nazwa": "COALMEX Sp. zo.o.",
          "kod_pocztowy": "33-331",
          "miejscowosc": "Stróże",
          "ulica": "---",
          "nr_domu": "357",
          "wojewodztwo_id": "6",
          "telefon": "572976164",
          "email": "stroze@coalmex.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 16:00",
          "godziny_otwarcia_sobota": "08:00 - 12:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "nowosądecki",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "gorlicki",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 167,
              "cena_obslugi_tona": "303.23000000000002",
              "created_at": "2023-02-21 07:10:01.893",
              "updated_at": "2023-02-21 07:10:01.893"
          }
      },
      {
          "id": 101,
          "nazwa": "TK TRANS Tokarczyk Agata i Jarosław Spółka Jawna",
          "kod_pocztowy": "33-300",
          "miejscowosc": "Nowy Sącz",
          "ulica": "Asnyka",
          "nr_domu": "1",
          "wojewodztwo_id": "6",
          "telefon": "185474722",
          "email": "biuro@thtrans.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": "08:00-12:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "nowosądecki",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "gorlicki",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 173,
              "cena_obslugi_tona": "291.02999999999997",
              "created_at": "2023-03-02 07:15:48.353",
              "updated_at": "2023-03-02 07:15:48.353"
          }
      },
      {
          "id": 102,
          "nazwa": "Błaszczyk Skład Materiałów Przemysłowo-Budowlanych Usługi Transportowo Sprzętowe Mieczysław i Jerzy Błaszczyk S.C.",
          "kod_pocztowy": "34-606",
          "miejscowosc": "Łukowica",
          "ulica": "Łukowica",
          "nr_domu": "494",
          "wojewodztwo_id": "6",
          "telefon": "183335041",
          "email": "biuro@mitex.limanowa.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00-16:00",
          "godziny_otwarcia_sobota": "07:00-13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "limanowski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "myślenicki",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 109,
              "cena_obslugi_tona": "104.54000000000001",
              "created_at": "2022-10-27 07:04:58.313",
              "updated_at": "2022-10-27 07:04:58.313"
          }
      },
      {
          "id": 108,
          "nazwa": "Firma Handlowo Usługowa mgr inż. Wojciech Kupczyk",
          "kod_pocztowy": "32-095",
          "miejscowosc": "Widoma",
          "ulica": "Nie dotyczy",
          "nr_domu": "49",
          "wojewodztwo_id": "6",
          "telefon": "662847408",
          "email": "kupczyk-kdw@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:00 -17:00",
          "godziny_otwarcia_sobota": "7:00 -13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "krakowski",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 154,
              "cena_obslugi_tona": "211.72",
              "created_at": "2023-02-15 10:42:22.543",
              "updated_at": "2023-02-15 10:42:22.543"
          }
      },
      {
          "id": 111,
          "nazwa": "COALMEX Sp. z o.o.",
          "kod_pocztowy": "33-111",
          "miejscowosc": "Koszyce Wielkie, Koszyce Małe",
          "ulica": "Spacerowa",
          "nr_domu": "44A",
          "wojewodztwo_id": "6",
          "telefon": "885402456",
          "email": "koszycemale@coalmex.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 15:00",
          "godziny_otwarcia_sobota": "Nieczynne",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "dąbrowski",
                  "wojewodztwo": "małopolskie"
              },
              {
                  "powiat": "tarnowski",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 166,
              "cena_obslugi_tona": "274.25",
              "created_at": "2023-02-21 07:09:28.160",
              "updated_at": "2023-02-21 07:09:28.160"
          }
      },
      {
          "id": 113,
          "nazwa": "TRESKO SKOTNICKI, TRĘBECKI SPÓŁKA JAWNA",
          "kod_pocztowy": "32-541",
          "miejscowosc": "Trzebinia",
          "ulica": "1 Maja",
          "nr_domu": "61",
          "wojewodztwo_id": "6",
          "telefon": "327536805",
          "email": "tresko@tlen.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "06:00 - 16:00",
          "godziny_otwarcia_sobota": "06:00 - 13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "chrzanowski",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 164,
              "cena_obslugi_tona": "179.69",
              "created_at": "2023-02-20 11:13:12.047",
              "updated_at": "2023-02-20 11:13:12.047"
          }
      },
      {
          "id": 140,
          "nazwa": "FH DOMAGA s.c. Monika i Piotr Skóra",
          "kod_pocztowy": "32-250",
          "miejscowosc": "Miechów - Charsznica",
          "ulica": "Sportowa",
          "nr_domu": "5",
          "wojewodztwo_id": "6",
          "telefon": "601418411",
          "email": "a.skora@domaga.pl",
          "geo_n": "50.390844600000001",
          "geo_e": "19.958363500000001",
          "godziny_otwarcia": "08:17:00",
          "godziny_otwarcia_sobota": "08:14:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "miechowski",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 171,
              "cena_obslugi_tona": "209.43000000000001",
              "created_at": "2023-02-23 07:19:51.190",
              "updated_at": "2023-02-23 07:19:51.190"
          }
      },
      {
          "id": 137,
          "nazwa": "FHUP ROLBUD Wiesław Stawowy",
          "kod_pocztowy": "34-100",
          "miejscowosc": "Tomice",
          "ulica": "Wadowicka",
          "nr_domu": "3",
          "wojewodztwo_id": "6",
          "telefon": "888630926",
          "email": "rolbud.kdw@onet.eu",
          "geo_n": "49.918698399999997",
          "geo_e": "19.508848",
          "godziny_otwarcia": "07:00 - 15:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "wadowicki",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 145,
              "cena_obslugi_tona": "161.62",
              "created_at": "2022-11-25 10:16:13.687",
              "updated_at": "2022-11-25 10:16:13.687"
          }
      },
      {
          "id": 136,
          "nazwa": "Zakład Transportowo Handlowy Andrzej Krysa",
          "kod_pocztowy": "32-420",
          "miejscowosc": "Gdów, Bilczyce",
          "ulica": "Bilczyce",
          "nr_domu": "330",
          "wojewodztwo_id": "6",
          "telefon": "452680657",
          "email": "magda-krysa@wp.pl",
          "geo_n": "49.929472099999998",
          "geo_e": "20.180885799999999",
          "godziny_otwarcia": "07:30 - 16:00",
          "godziny_otwarcia_sobota": "07:30-13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "wielicki",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 168,
              "cena_obslugi_tona": "230.02000000000001",
              "created_at": "2023-02-21 07:13:44.107",
              "updated_at": "2023-02-21 07:13:44.107"
          }
      },
      {
          "id": 126,
          "nazwa": "Zakład Handlowo-Usługowy PYLOS Marzena Żądlak",
          "kod_pocztowy": "34-472",
          "miejscowosc": "Piekielnik",
          "ulica": "Piekielnik",
          "nr_domu": "175",
          "wojewodztwo_id": "6",
          "telefon": "882116572",
          "email": "zhupylospiekielnik@wp.pl",
          "geo_n": "49.4774277",
          "geo_e": "19.772004500000001",
          "godziny_otwarcia": "8:00-17:00",
          "godziny_otwarcia_sobota": "8:00-14:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "nowotarski",
                  "wojewodztwo": "małopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 6,
              "nazwa": "małopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 133,
              "cena_obslugi_tona": "123.54000000000001",
              "created_at": "2022-11-09 10:21:02.803",
              "updated_at": "2022-11-09 10:21:02.803"
          }
      }
  ],
  "opolskie": [
      {
          "id": 47,
          "nazwa": "Firma Handlowo Usługowa \"KONRAD\" Konrad Grześka",
          "kod_pocztowy": "47-280",
          "miejscowosc": "Pawłowiczki",
          "ulica": "Kozielska",
          "nr_domu": "4",
          "wojewodztwo_id": "8",
          "telefon": "601239656",
          "email": "konrad.grzeska@interia.eu",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 -18:00",
          "godziny_otwarcia_sobota": "08:00 -13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "głubczycki",
                  "wojewodztwo": "opolskie"
              },
              {
                  "powiat": "kędzierzyńsko-kozielski",
                  "wojewodztwo": "opolskie"
              }
          ],
          "wojewodztwo": {
              "id": 8,
              "nazwa": "opolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 61,
              "cena_obslugi_tona": "191.72999999999999",
              "created_at": "2022-09-19 13:51:16.070",
              "updated_at": "2022-09-19 13:51:16.070"
          }
      },
      {
          "id": 70,
          "nazwa": "Adam Kobylański",
          "kod_pocztowy": "49-300",
          "miejscowosc": "Brzeg",
          "ulica": "Wrocławska",
          "nr_domu": "14",
          "wojewodztwo_id": "8",
          "telefon": "774045222",
          "email": "adamkobylanski@o2.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": "-",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "opolski",
                  "wojewodztwo": "opolskie"
              },
              {
                  "powiat": "namysłowski",
                  "wojewodztwo": "opolskie"
              },
              {
                  "powiat": "kluczborski",
                  "wojewodztwo": "opolskie"
              },
              {
                  "powiat": "brzeski",
                  "wojewodztwo": "opolskie"
              }
          ],
          "wojewodztwo": {
              "id": 8,
              "nazwa": "opolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 77,
              "cena_obslugi_tona": "243.53999999999999",
              "created_at": "2022-09-26 11:44:43.737",
              "updated_at": "2022-09-26 11:44:43.737"
          }
      },
      {
          "id": 77,
          "nazwa": "TOMEX-SKŁAD OPAŁU SKUP ZŁOMU Tomasz Stanek",
          "kod_pocztowy": "48-200",
          "miejscowosc": "Prudnik",
          "ulica": "Niemysłowice",
          "nr_domu": "61 B",
          "wojewodztwo_id": "8",
          "telefon": "731033721",
          "email": "tomexpgg@op.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00-17:00",
          "godziny_otwarcia_sobota": "07:00-14:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "prudnicki",
                  "wojewodztwo": "opolskie"
              },
              {
                  "powiat": "nyski",
                  "wojewodztwo": "opolskie"
              },
              {
                  "powiat": "krapkowicki",
                  "wojewodztwo": "opolskie"
              }
          ],
          "wojewodztwo": {
              "id": 8,
              "nazwa": "opolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 83,
              "cena_obslugi_tona": "246.0",
              "created_at": "2022-09-27 09:24:31.977",
              "updated_at": "2022-09-27 09:24:31.977"
          }
      },
      {
          "id": 20,
          "nazwa": "Firma Handlowo-Usługowa Liradjag Rafał Gajda",
          "kod_pocztowy": "46-320",
          "miejscowosc": "Praszka, Wierzbie",
          "ulica": "---",
          "nr_domu": "31A",
          "wojewodztwo_id": "8",
          "telefon": "696596555",
          "email": "biuroliradjag@protonmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00 - 16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "oleski",
                  "wojewodztwo": "opolskie"
              },
              {
                  "powiat": "strzelecki",
                  "wojewodztwo": "opolskie"
              }
          ],
          "wojewodztwo": {
              "id": 8,
              "nazwa": "opolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 31,
              "cena_obslugi_tona": "191.88",
              "created_at": "2022-09-06 11:49:49.127",
              "updated_at": "2022-09-06 11:49:49.127"
          }
      },
      {
          "id": 114,
          "nazwa": "LMT Trading Aneta Matejaszek",
          "kod_pocztowy": "48-303",
          "miejscowosc": "Nysa",
          "ulica": "Podolska",
          "nr_domu": "19",
          "wojewodztwo_id": "8",
          "telefon": "774093780",
          "email": "aneta.matejaszek@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "nyski",
                  "wojewodztwo": "opolskie"
              }
          ],
          "wojewodztwo": {
              "id": 8,
              "nazwa": "opolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 121,
              "cena_obslugi_tona": "246.0",
              "created_at": "2022-11-02 09:32:29.993",
              "updated_at": "2022-11-02 09:32:29.993"
          }
      },
      {
          "id": 118,
          "nazwa": "Baros Zdzisława Firma Usługowo-Handlowa \"DUET\"",
          "kod_pocztowy": "46-325",
          "miejscowosc": "Rudniki",
          "ulica": "Żytniów",
          "nr_domu": "36",
          "wojewodztwo_id": "8",
          "telefon": "880943415",
          "email": "duet.baros@op.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 16:00",
          "godziny_otwarcia_sobota": "08:00 - 13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "oleski",
                  "wojewodztwo": "opolskie"
              },
              {
                  "powiat": "strzelecki",
                  "wojewodztwo": "opolskie"
              }
          ],
          "wojewodztwo": {
              "id": 8,
              "nazwa": "opolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 126,
              "cena_obslugi_tona": "191.88",
              "created_at": "2022-11-02 10:22:23.337",
              "updated_at": "2022-11-02 10:22:23.337"
          }
      },
      {
          "id": 132,
          "nazwa": "Usługi Transportowe Chrobot Kazimierz",
          "kod_pocztowy": "49-156",
          "miejscowosc": "Gracze",
          "ulica": "Bazaltowa",
          "nr_domu": "7A",
          "wojewodztwo_id": "8",
          "telefon": "774609893",
          "email": "chrobotk@interia.pl",
          "geo_n": "50.6892724",
          "geo_e": "17.559836000000001",
          "godziny_otwarcia": "09:00 - 17:00",
          "godziny_otwarcia_sobota": "09:00 - 13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "opolski",
                  "wojewodztwo": "opolskie"
              }
          ],
          "wojewodztwo": {
              "id": 8,
              "nazwa": "opolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 139,
              "cena_obslugi_tona": "243.53999999999999",
              "created_at": "2022-11-20 10:12:29.150",
              "updated_at": "2022-11-20 10:12:29.150"
          }
      },
      {
          "id": 139,
          "nazwa": "IMEX-PIECHOTA Sp.K.",
          "kod_pocztowy": "46-042",
          "miejscowosc": "Brynica",
          "ulica": "Ogrodowa",
          "nr_domu": "12",
          "wojewodztwo_id": "8",
          "telefon": "793415009",
          "email": "imexpiechota3@gmail.com",
          "geo_n": "50.794586299999999",
          "geo_e": "17.949237100000001",
          "godziny_otwarcia": "07:00 -17:00",
          "godziny_otwarcia_sobota": "07:00 -15:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": "https://imexpiechota.pl",
          "obs_powiaty": [
              {
                  "powiat": "opolski",
                  "wojewodztwo": "opolskie"
              }
          ],
          "wojewodztwo": {
              "id": 8,
              "nazwa": "opolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 147,
              "cena_obslugi_tona": "243.53999999999999",
              "created_at": "2022-12-02 10:40:47.543",
              "updated_at": "2022-12-02 10:40:47.543"
          }
      },
      {
          "id": 144,
          "nazwa": "FHU KOBA Zjawin Jakub",
          "kod_pocztowy": "48-130",
          "miejscowosc": "Kietrz",
          "ulica": "Kozłówki",
          "nr_domu": "63",
          "wojewodztwo_id": "8",
          "telefon": "48724444111",
          "email": "koba26@wp.pl",
          "geo_n": "50.087288000000001",
          "geo_e": "17.969515600000001",
          "godziny_otwarcia": "08.00-16.00",
          "godziny_otwarcia_sobota": "08.00-12.00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "głubczycki",
                  "wojewodztwo": "opolskie"
              }
          ],
          "wojewodztwo": {
              "id": 8,
              "nazwa": "opolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 152,
              "cena_obslugi_tona": "191.72999999999999",
              "created_at": "2022-12-29 13:44:26.287",
              "updated_at": "2022-12-29 13:44:26.287"
          }
      }
  ],
  "podkarpackie": [
      {
          "id": 43,
          "nazwa": "Przedsiębiorstwo Wielobranżowe \"SANCO\" Józef Sanocki",
          "kod_pocztowy": "37-716",
          "miejscowosc": "Orły",
          "ulica": "Handlowa",
          "nr_domu": "4",
          "wojewodztwo_id": "9",
          "telefon": "600810661",
          "email": "orlysanco@o2.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7.00 - 16.00",
          "godziny_otwarcia_sobota": "7.00 - 13.00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "przemyski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "lubaczowski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "jarosławski",
                  "wojewodztwo": "podkarpackie"
              }
          ],
          "wojewodztwo": {
              "id": 9,
              "nazwa": "podkarpackie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 55,
              "cena_obslugi_tona": "339.30000000000001",
              "created_at": "2022-09-17 11:37:03.980",
              "updated_at": "2022-09-17 11:37:03.980"
          }
      },
      {
          "id": 1,
          "nazwa": "\"PANMAR\" Czekańska Szmyd Sp. J.",
          "kod_pocztowy": "38-623",
          "miejscowosc": "Uherce Mineralne",
          "ulica": "Stacja PKP",
          "nr_domu": "1",
          "wojewodztwo_id": "9",
          "telefon": "134618383",
          "email": "so@panmar.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 15:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "bieszczadzki",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "leski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "sanocki",
                  "wojewodztwo": "podkarpackie"
              }
          ],
          "wojewodztwo": {
              "id": 9,
              "nazwa": "podkarpackie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 165,
              "cena_obslugi_tona": "390.93000000000001",
              "created_at": "2023-02-20 11:14:50.933",
              "updated_at": "2023-02-20 11:14:50.933"
          }
      },
      {
          "id": 15,
          "nazwa": "Firma Handlowo- Usługowa Wiesław Hajder",
          "kod_pocztowy": "36-050",
          "miejscowosc": "Wólka Sokołowska",
          "ulica": "Wólka Sokołowska",
          "nr_domu": "237",
          "wojewodztwo_id": "9",
          "telefon": "177728081",
          "email": "hajder@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00-16:00",
          "godziny_otwarcia_sobota": "8:00-13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "przeworski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "łańcucki",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "rzeszowski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "leżajski",
                  "wojewodztwo": "podkarpackie"
              }
          ],
          "wojewodztwo": {
              "id": 9,
              "nazwa": "podkarpackie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 27,
              "cena_obslugi_tona": "277.91000000000003",
              "created_at": "2022-09-06 07:05:50.783",
              "updated_at": "2022-09-06 07:05:50.783"
          }
      },
      {
          "id": 31,
          "nazwa": "Firma Handlowo-Usługowa \"Węglobud\" Tadeusz Nieroda",
          "kod_pocztowy": "39-120",
          "miejscowosc": "Sędziszów Małopolski",
          "ulica": "Węglowskiego",
          "nr_domu": "6",
          "wojewodztwo_id": "9",
          "telefon": "728356017",
          "email": "fhu.weglobud.t.n@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7.00 - 15.00",
          "godziny_otwarcia_sobota": "7.00 - 12.00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "mielecki",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "dębicki",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "ropczycko-sędziszowski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "kolbuszowski",
                  "wojewodztwo": "podkarpackie"
              }
          ],
          "wojewodztwo": {
              "id": 9,
              "nazwa": "podkarpackie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 42,
              "cena_obslugi_tona": "163.49000000000001",
              "created_at": "2022-09-13 09:20:50.400",
              "updated_at": "2022-09-13 09:20:50.400"
          }
      },
      {
          "id": 22,
          "nazwa": "Firma Handlowo-Usługowo-Produkcyjna SANDRA II",
          "kod_pocztowy": "38-207",
          "miejscowosc": "Przysieki",
          "ulica": "Przysieki",
          "nr_domu": "59A",
          "wojewodztwo_id": "9",
          "telefon": "698597813",
          "email": "sandra2wegiel@op.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 -16:00",
          "godziny_otwarcia_sobota": "08:00 -13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "strzyżowski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "krośnieński",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "brzozowski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "jasielski",
                  "wojewodztwo": "podkarpackie"
              }
          ],
          "wojewodztwo": {
              "id": 9,
              "nazwa": "podkarpackie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 33,
              "cena_obslugi_tona": "403.69",
              "created_at": "2022-09-07 12:43:26.480",
              "updated_at": "2022-09-07 12:43:26.480"
          }
      },
      {
          "id": 93,
          "nazwa": "Firma Handlowo Usługowa Wiesław Hajder",
          "kod_pocztowy": "39-450",
          "miejscowosc": "Dąbrowica",
          "ulica": "Dąbrowica",
          "nr_domu": "4",
          "wojewodztwo_id": "9",
          "telefon": "535709585",
          "email": "hajder.tarnobrzeg@onet.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": "08:00-13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "stalowowolski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "tarnobrzeski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "niżański",
                  "wojewodztwo": "podkarpackie"
              }
          ],
          "wojewodztwo": {
              "id": 9,
              "nazwa": "podkarpackie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 100,
              "cena_obslugi_tona": "470.83999999999997",
              "created_at": "2022-10-06 13:46:30.263",
              "updated_at": "2022-10-06 13:46:30.263"
          }
      },
      {
          "id": 109,
          "nazwa": "Zakład Usługowy - Transport Leśny Dul Wiesław",
          "kod_pocztowy": "39-308",
          "miejscowosc": "Przebendów",
          "ulica": "Przebendów",
          "nr_domu": "42 b",
          "wojewodztwo_id": "9",
          "telefon": "146669762",
          "email": "dulek81@interia.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00-16:00",
          "godziny_otwarcia_sobota": "-",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "mielecki",
                  "wojewodztwo": "podkarpackie"
              }
          ],
          "wojewodztwo": {
              "id": 9,
              "nazwa": "podkarpackie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 116,
              "cena_obslugi_tona": "163.49000000000001",
              "created_at": "2022-10-27 11:10:55.040",
              "updated_at": "2022-10-27 11:10:55.040"
          }
      },
      {
          "id": 112,
          "nazwa": "PANMAR Czekańska Szmyd Spółka Jawna",
          "kod_pocztowy": "37-700",
          "miejscowosc": "Przemyśl",
          "ulica": "Nestora",
          "nr_domu": ".",
          "wojewodztwo_id": "9",
          "telefon": "166783019",
          "email": "kdw@panmar.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 15:00",
          "godziny_otwarcia_sobota": "nieczynne",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "przemyski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "lubaczowski",
                  "wojewodztwo": "podkarpackie"
              },
              {
                  "powiat": "jarosławski",
                  "wojewodztwo": "podkarpackie"
              }
          ],
          "wojewodztwo": {
              "id": 9,
              "nazwa": "podkarpackie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 119,
              "cena_obslugi_tona": "339.30000000000001",
              "created_at": "2022-10-27 13:50:18.840",
              "updated_at": "2022-10-27 13:50:18.840"
          }
      },
      {
          "id": 117,
          "nazwa": "WĘGLOMAT TRZCIANA Sp. z o.o., Spółka Komandytowa",
          "kod_pocztowy": "38-200",
          "miejscowosc": "Jasło",
          "ulica": "Przemysłowa",
          "nr_domu": "4",
          "wojewodztwo_id": "9",
          "telefon": "506176114",
          "email": "weglomat.jaslo@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8- 16",
          "godziny_otwarcia_sobota": "nieczynne",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "jasielski",
                  "wojewodztwo": "podkarpackie"
              }
          ],
          "wojewodztwo": {
              "id": 9,
              "nazwa": "podkarpackie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 160,
              "cena_obslugi_tona": "323.81999999999999",
              "created_at": "2023-02-20 08:22:11.173",
              "updated_at": "2023-02-20 08:22:11.173"
          }
      }
  ],
  "podlaskie": [
      {
          "id": 40,
          "nazwa": "SOBIANEK Sp. z o.o.",
          "kod_pocztowy": "17-300",
          "miejscowosc": "Siemiatycze",
          "ulica": "11 Listopada",
          "nr_domu": "253",
          "wojewodztwo_id": "10",
          "telefon": "510842709",
          "email": "siemiatycze@sobianek.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 16:00",
          "godziny_otwarcia_sobota": "08:00 - 15:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "siemiatycki",
                  "wojewodztwo": "podlaskie"
              },
              {
                  "powiat": "hajnowski",
                  "wojewodztwo": "podlaskie"
              },
              {
                  "powiat": "bielski",
                  "wojewodztwo": "podlaskie"
              },
              {
                  "powiat": "wysokomazowiecki",
                  "wojewodztwo": "podlaskie"
              }
          ],
          "wojewodztwo": {
              "id": 10,
              "nazwa": "podlaskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 53,
              "cena_obslugi_tona": "372.36000000000001",
              "created_at": "2022-09-16 06:46:40.733",
              "updated_at": "2022-09-16 06:46:40.733"
          }
      },
      {
          "id": 16,
          "nazwa": "Handel Artykułami Przemysłowymi Krzysztof Kołakowski",
          "kod_pocztowy": "18-300",
          "miejscowosc": "Wola Zambrowska",
          "ulica": "Spokojna",
          "nr_domu": "4",
          "wojewodztwo_id": "10",
          "telefon": "795926000",
          "email": "kolakowski.k@op.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00-16:00",
          "godziny_otwarcia_sobota": "07.:00-14:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "kolneński",
                  "wojewodztwo": "podlaskie"
              },
              {
                  "powiat": "łomżyński",
                  "wojewodztwo": "podlaskie"
              },
              {
                  "powiat": "zambrowski",
                  "wojewodztwo": "podlaskie"
              },
              {
                  "powiat": "grajewski",
                  "wojewodztwo": "podlaskie"
              },
              {
                  "powiat": "moniecki",
                  "wojewodztwo": "podlaskie"
              }
          ],
          "wojewodztwo": {
              "id": 10,
              "nazwa": "podlaskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 26,
              "cena_obslugi_tona": "553.20000000000005",
              "created_at": "2022-09-05 14:59:34.940",
              "updated_at": "2022-09-05 14:59:34.940"
          }
      },
      {
          "id": 88,
          "nazwa": "Przedsiębiorstwo Transportowo-Sprzętowe Budownictwa \"Transbud Ełk\" Sp.z o.o.",
          "kod_pocztowy": "16-400",
          "miejscowosc": "Suwałki",
          "ulica": "Północna",
          "nr_domu": "27",
          "wojewodztwo_id": "10",
          "telefon": "789409759",
          "email": "gkryjer@transbud.elk.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 16:00",
          "godziny_otwarcia_sobota": "07:00 - 13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "suwalski",
                  "wojewodztwo": "podlaskie"
              },
              {
                  "powiat": "sejneński",
                  "wojewodztwo": "podlaskie"
              },
              {
                  "powiat": "augustowski",
                  "wojewodztwo": "podlaskie"
              }
          ],
          "wojewodztwo": {
              "id": 10,
              "nazwa": "podlaskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 95,
              "cena_obslugi_tona": "472.00999999999999",
              "created_at": "2022-10-06 10:49:44.967",
              "updated_at": "2022-10-06 10:49:44.967"
          }
      }
  ],
  "pomorskie": [
      {
          "id": 71,
          "nazwa": "JOANNA SILDATK Koszałka Skład Opału",
          "kod_pocztowy": "84-300",
          "miejscowosc": "Lębork-Mosty",
          "ulica": "Witosa",
          "nr_domu": "1 a",
          "wojewodztwo_id": "11",
          "telefon": "661810702",
          "email": "biuro@koszalkaopal.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-17:00",
          "godziny_otwarcia_sobota": "08:00-13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "lęborski",
                  "wojewodztwo": "pomorskie"
              },
              {
                  "powiat": "wejherowski",
                  "wojewodztwo": "pomorskie"
              },
              {
                  "powiat": "pucki",
                  "wojewodztwo": "pomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 11,
              "nazwa": "pomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 78,
              "cena_obslugi_tona": "405.92000000000002",
              "created_at": "2022-09-26 12:12:47.193",
              "updated_at": "2022-09-26 12:12:47.193"
          }
      },
      {
          "id": 5,
          "nazwa": "ANT-POL Andrzej Spica",
          "kod_pocztowy": "89-650",
          "miejscowosc": "Czersk",
          "ulica": "Starogardzka",
          "nr_domu": "112",
          "wojewodztwo_id": "11",
          "telefon": "518568938",
          "email": "ant-pol.spica@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 -16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "człuchowski",
                  "wojewodztwo": "pomorskie"
              },
              {
                  "powiat": "chojnicki",
                  "wojewodztwo": "pomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 11,
              "nazwa": "pomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 16,
              "cena_obslugi_tona": "544.71000000000004",
              "created_at": "2022-09-01 13:44:01.350",
              "updated_at": "2022-09-01 13:44:01.350"
          }
      },
      {
          "id": 11,
          "nazwa": "Firma Usługowo-Handlowa „DOMBUD” Pryczkowski Spółka Jawna",
          "kod_pocztowy": "83-300",
          "miejscowosc": "Łapalice",
          "ulica": "Kartuska",
          "nr_domu": "10",
          "wojewodztwo_id": "11",
          "telefon": "691191783",
          "email": "wegiel@dombudlapalice.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8.00 - 16.00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "gdański",
                  "wojewodztwo": "pomorskie"
              },
              {
                  "powiat": "kartuski",
                  "wojewodztwo": "pomorskie"
              },
              {
                  "powiat": "Gdańsk",
                  "wojewodztwo": "pomorskie"
              },
              {
                  "powiat": "Gdynia",
                  "wojewodztwo": "pomorskie"
              },
              {
                  "powiat": "Sopot",
                  "wojewodztwo": "pomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 11,
              "nazwa": "pomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 159,
              "cena_obslugi_tona": "295.19999999999999",
              "created_at": "2023-02-16 11:23:16.770",
              "updated_at": "2023-02-16 11:23:16.770"
          }
      },
      {
          "id": 87,
          "nazwa": "Skład Opału Węglokoks Tomasz Prądzyński",
          "kod_pocztowy": "76-200",
          "miejscowosc": "Słupsk",
          "ulica": "Lutosławskiego",
          "nr_domu": "14a",
          "wojewodztwo_id": "11",
          "telefon": "598424611",
          "email": "weglokoks1@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": "08:00-13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "słupski",
                  "wojewodztwo": "pomorskie"
              },
              {
                  "powiat": "bytowski",
                  "wojewodztwo": "pomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 11,
              "nazwa": "pomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 94,
              "cena_obslugi_tona": "369.79000000000002",
              "created_at": "2022-10-05 15:02:38.453",
              "updated_at": "2022-10-05 15:02:38.453"
          }
      }
  ],
  "warmińsko-mazurskie": [
      {
          "id": 6,
          "nazwa": "Przedsiębiorstwo Handlowo Usługowe HEOPS Henryk Orłowski",
          "kod_pocztowy": "14-400",
          "miejscowosc": "Pasłęk",
          "ulica": "Dworcowa",
          "nr_domu": "1",
          "wojewodztwo_id": "14",
          "telefon": "505552610",
          "email": "tomasz@heops.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "od 7:00 do 17:00",
          "godziny_otwarcia_sobota": "od 7:00 do 14:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "elbląski",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "bartoszycki",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "lidzbarski",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "braniewski",
                  "wojewodztwo": "warmińsko-mazurskie"
              }
          ],
          "wojewodztwo": {
              "id": 14,
              "nazwa": "warmińsko-mazurskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 19,
              "cena_obslugi_tona": "399.75",
              "created_at": "2022-09-01 13:49:33.417",
              "updated_at": "2022-09-01 13:49:33.417"
          }
      },
      {
          "id": 25,
          "nazwa": "Przedsiębiorstwo Usługowe Agmar",
          "kod_pocztowy": "10-900",
          "miejscowosc": "Olsztyn",
          "ulica": "Lubelska",
          "nr_domu": "1A",
          "wojewodztwo_id": "14",
          "telefon": "507530779",
          "email": "olsztynagmar@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00-16:00",
          "godziny_otwarcia_sobota": "nieczynne",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "olsztyński",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "szczycieński",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "nidzicki",
                  "wojewodztwo": "warmińsko-mazurskie"
              }
          ],
          "wojewodztwo": {
              "id": 14,
              "nazwa": "warmińsko-mazurskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 36,
              "cena_obslugi_tona": "603.44000000000005",
              "created_at": "2022-09-08 11:06:37.997",
              "updated_at": "2022-09-08 11:06:37.997"
          }
      },
      {
          "id": 27,
          "nazwa": "Katarzyna Kempczyńska Firma Handlowo-Usługowo-Transportowa \"KASIA\"",
          "kod_pocztowy": "13-230",
          "miejscowosc": "Lidzbark",
          "ulica": "Działdowska",
          "nr_domu": "26/7",
          "wojewodztwo_id": "14",
          "telefon": "604120805",
          "email": "wegiel.lidzbark@wp.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:30 - 16:00",
          "godziny_otwarcia_sobota": "7:30 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "działdowski",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "nowomiejski",
                  "wojewodztwo": "warmińsko-mazurskie"
              }
          ],
          "wojewodztwo": {
              "id": 14,
              "nazwa": "warmińsko-mazurskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 38,
              "cena_obslugi_tona": "370.13",
              "created_at": "2022-09-09 13:10:54.023",
              "updated_at": "2022-09-09 13:10:54.023"
          }
      },
      {
          "id": 3,
          "nazwa": "Gminna Spółdzielnia \"Samopomoc Chłopska\" w Gardei",
          "kod_pocztowy": "14-220",
          "miejscowosc": "Kisielice",
          "ulica": "Kolejowa",
          "nr_domu": "3 A",
          "wojewodztwo_id": "14",
          "telefon": "552756144",
          "email": "gsgardeja@post.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:30 - 15:30",
          "godziny_otwarcia_sobota": "8:30 -12:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "iławski",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "ostródzki",
                  "wojewodztwo": "warmińsko-mazurskie"
              }
          ],
          "wojewodztwo": {
              "id": 14,
              "nazwa": "warmińsko-mazurskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 12,
              "cena_obslugi_tona": "421.39999999999998",
              "created_at": "2022-09-01 13:41:36.573",
              "updated_at": "2022-09-01 13:41:36.573"
          }
      },
      {
          "id": 66,
          "nazwa": "Skłodowski Spółka Jawna",
          "kod_pocztowy": "19-420",
          "miejscowosc": "Kowale Oleckie",
          "ulica": "Mściszewo",
          "nr_domu": "1",
          "wojewodztwo_id": "14",
          "telefon": "608482100",
          "email": "a.dziadolik@sklodowski.com.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 16:00",
          "godziny_otwarcia_sobota": "08:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "piski",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "gołdapski",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "giżycki",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "kętrzyński",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "mrągowski",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "olecki",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "ełcki",
                  "wojewodztwo": "warmińsko-mazurskie"
              },
              {
                  "powiat": "węgorzewski",
                  "wojewodztwo": "warmińsko-mazurskie"
              }
          ],
          "wojewodztwo": {
              "id": 14,
              "nazwa": "warmińsko-mazurskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 73,
              "cena_obslugi_tona": "483.75",
              "created_at": "2022-09-20 13:53:55.607",
              "updated_at": "2022-09-20 13:53:55.607"
          }
      }
  ],
  "wielkopolskie": [
      {
          "id": 73,
          "nazwa": "Farmer",
          "kod_pocztowy": "62-420",
          "miejscowosc": "Strzałkowo",
          "ulica": "ul. Powstańców Wielkopolskich",
          "nr_domu": "7",
          "wojewodztwo_id": "15",
          "telefon": "632750233",
          "email": "farmer2@selcom.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:00-15:00",
          "godziny_otwarcia_sobota": "-",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "słupecki",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "koniński",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 80,
              "cena_obslugi_tona": "163.88999999999999",
              "created_at": "2022-09-26 13:07:04.313",
              "updated_at": "2022-09-26 13:07:04.313"
          }
      },
      {
          "id": 35,
          "nazwa": "PPHU ANMAR Marcin Skała",
          "kod_pocztowy": "62-045",
          "miejscowosc": "Pniewy",
          "ulica": "Poznańska",
          "nr_domu": "52 A",
          "wojewodztwo_id": "15",
          "telefon": "698038581",
          "email": "biuro@anmarpniewy.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00-17:00",
          "godziny_otwarcia_sobota": "8:00-14:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "międzychodzki",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "szamotulski",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "czarnkowsko-trzcianecki",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "nowotomyski",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 47,
              "cena_obslugi_tona": "208.09",
              "created_at": "2022-09-15 12:50:20.383",
              "updated_at": "2022-09-15 12:50:20.383"
          }
      },
      {
          "id": 37,
          "nazwa": "\"Bol-Ann\" A.T.M. Stelmaszykowie",
          "kod_pocztowy": "62-200",
          "miejscowosc": "Gniezno",
          "ulica": "Witkowska",
          "nr_domu": "82a",
          "wojewodztwo_id": "15",
          "telefon": "698887000",
          "email": "bolann@bolann.com.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08.00-16,00",
          "godziny_otwarcia_sobota": "08.00-13.00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "gnieźnieński",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 49,
              "cena_obslugi_tona": "506.26999999999998",
              "created_at": "2022-09-15 12:52:13.867",
              "updated_at": "2022-09-15 12:52:13.867"
          }
      },
      {
          "id": 65,
          "nazwa": "Przedsiębiorstwo Produkcyjno Handlowo Usługowe \"TRANSSTONE\"",
          "kod_pocztowy": "62-070",
          "miejscowosc": "Konarzewo",
          "ulica": "Kościelna",
          "nr_domu": "96",
          "wojewodztwo_id": "15",
          "telefon": "618148400",
          "email": "transstone.biuro@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00-16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "poznański",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 72,
              "cena_obslugi_tona": "257.63999999999999",
              "created_at": "2022-09-20 13:42:05.030",
              "updated_at": "2022-09-20 13:42:05.030"
          }
      },
      {
          "id": 12,
          "nazwa": "KUMA Magda &amp; Magda Spółka Jawna",
          "kod_pocztowy": "63-600",
          "miejscowosc": "Kępno",
          "ulica": "Olszowa; ul. Granitowa",
          "nr_domu": "9",
          "wojewodztwo_id": "15",
          "telefon": "627826000",
          "email": "biuro@kuma.com.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-17:00",
          "godziny_otwarcia_sobota": "7:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "kępiński",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "ostrzeszowski",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 153,
              "cena_obslugi_tona": "281.12",
              "created_at": "2023-02-15 08:22:39.587",
              "updated_at": "2023-02-15 08:22:39.587"
          }
      },
      {
          "id": 29,
          "nazwa": "WĘGLOMET sp. z o.o.",
          "kod_pocztowy": "63-400",
          "miejscowosc": "Ostrów Wielkopolski",
          "ulica": "Rejtana",
          "nr_domu": "54",
          "wojewodztwo_id": "15",
          "telefon": "627381550",
          "email": "biuro@weglomet.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "od 7:00 do 15:00",
          "godziny_otwarcia_sobota": "od 7 do12",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "kaliski",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "ostrowski",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "pleszewski",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 52,
              "cena_obslugi_tona": "167.44999999999999",
              "created_at": "2022-09-15 13:29:54.870",
              "updated_at": "2022-09-15 13:29:54.870"
          }
      },
      {
          "id": 26,
          "nazwa": "Sobmar Mariusz Sobczak",
          "kod_pocztowy": "62-700",
          "miejscowosc": "Turek - Żuki",
          "ulica": "---",
          "nr_domu": "38c",
          "wojewodztwo_id": "15",
          "telefon": "667630377",
          "email": "biurosobmar@o2.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8-18",
          "godziny_otwarcia_sobota": "8-14",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "kolski",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "turecki",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 37,
              "cena_obslugi_tona": "355.61000000000001",
              "created_at": "2022-09-09 11:31:49.217",
              "updated_at": "2022-09-09 11:31:49.217"
          }
      },
      {
          "id": 45,
          "nazwa": "PPHU RATBET Artur Rataj",
          "kod_pocztowy": "63-860",
          "miejscowosc": "Pogorzela",
          "ulica": "Gostyńska",
          "nr_domu": "20",
          "wojewodztwo_id": "15",
          "telefon": "786829419",
          "email": "kdw@ratbet.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7.00 - 16.30",
          "godziny_otwarcia_sobota": "8.00 - 13.00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "gostyński",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "jarociński",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "rawicki",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "krotoszyński",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 57,
              "cena_obslugi_tona": "199.25999999999999",
              "created_at": "2022-09-19 07:57:18.603",
              "updated_at": "2022-09-19 07:57:18.603"
          }
      },
      {
          "id": 92,
          "nazwa": "KAMAR S.K.",
          "kod_pocztowy": "89-300",
          "miejscowosc": "Wyrzysk",
          "ulica": "22 Stycznia",
          "nr_domu": "40",
          "wojewodztwo_id": "15",
          "telefon": "533651456",
          "email": "l.lukowski@kamarwyrzysk.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 17:00",
          "godziny_otwarcia_sobota": "07:00 - 14:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "wągrowiecki",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "złotowski",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "pilski",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "chodzieski",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "obornicki",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 99,
              "cena_obslugi_tona": "352.22000000000003",
              "created_at": "2022-10-06 13:38:12.143",
              "updated_at": "2022-10-06 13:38:12.143"
          }
      },
      {
          "id": 91,
          "nazwa": "WEGMAR Mariola Kolańczyk-Stokłosa",
          "kod_pocztowy": "64-200",
          "miejscowosc": "Wolsztyn",
          "ulica": "Niałek Wielki",
          "nr_domu": "84",
          "wojewodztwo_id": "15",
          "telefon": "604618359",
          "email": "wegmarkdw@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "wolsztyński",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "grodziski",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "leszczyński",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "kościański",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 97,
              "cena_obslugi_tona": "271.44",
              "created_at": "2022-10-06 13:17:45.457",
              "updated_at": "2022-10-06 13:17:45.457"
          }
      },
      {
          "id": 89,
          "nazwa": "KOSŁOM Koczorowscy S.J.",
          "kod_pocztowy": "62-300",
          "miejscowosc": "Września",
          "ulica": "Gen. Sikorskiego",
          "nr_domu": "36",
          "wojewodztwo_id": "15",
          "telefon": "614361588",
          "email": "wegiel@koslom.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00-15:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "średzki",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "wrzesiński",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "śremski",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 96,
              "cena_obslugi_tona": "265.31",
              "created_at": "2022-10-06 12:02:12.777",
              "updated_at": "2022-10-06 12:02:12.777"
          }
      },
      {
          "id": 99,
          "nazwa": "GS \"Samopomoc Chłopska\" w Wierzbinku",
          "kod_pocztowy": "62-619",
          "miejscowosc": "Sadlno",
          "ulica": "Wierzbinek",
          "nr_domu": "35",
          "wojewodztwo_id": "15",
          "telefon": "602644066",
          "email": "sklep2gswierzbinek@op.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:30 - 15:30",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "słupecki",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "koniński",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 106,
              "cena_obslugi_tona": "163.88999999999999",
              "created_at": "2022-10-25 12:28:42.287",
              "updated_at": "2022-10-25 12:28:42.287"
          }
      },
      {
          "id": 104,
          "nazwa": "Eugeniusz Sobasik Przedsiebiorstwo Usługowo-Handlowe",
          "kod_pocztowy": "63-910",
          "miejscowosc": "Konary",
          "ulica": "Nie dotyczy",
          "nr_domu": "10",
          "wojewodztwo_id": "15",
          "telefon": "655477784",
          "email": "biuro@sobasikkonary.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 17:00",
          "godziny_otwarcia_sobota": "08:00 - 15:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "gostyński",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "jarociński",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "rawicki",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "krotoszyński",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 111,
              "cena_obslugi_tona": "199.25999999999999",
              "created_at": "2022-10-27 07:18:43.753",
              "updated_at": "2022-10-27 07:18:43.753"
          }
      },
      {
          "id": 116,
          "nazwa": "P.U.H. Jakubczyk S.C. Henryk i Tomasz Jakubczyk",
          "kod_pocztowy": "63-600",
          "miejscowosc": "Kępno",
          "ulica": "Mikorzyn",
          "nr_domu": "95",
          "wojewodztwo_id": "15",
          "telefon": "662616937",
          "email": "kontakt@htjakubczyk.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": "08:00-12:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "kępiński",
                  "wojewodztwo": "wielkopolskie"
              },
              {
                  "powiat": "ostrzeszowski",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 123,
              "cena_obslugi_tona": "285.85000000000002",
              "created_at": "2022-11-02 09:52:17.467",
              "updated_at": "2022-11-02 09:52:17.467"
          }
      },
      {
          "id": 122,
          "nazwa": "PPHU \"ROL-ZBYT\" Ewa Wawrzyniak",
          "kod_pocztowy": "63-140",
          "miejscowosc": "Dolsk",
          "ulica": "Krupczyn",
          "nr_domu": "10",
          "wojewodztwo_id": "15",
          "telefon": "537435372",
          "email": "kdw.rolzbytdolsk@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "śremski",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 130,
              "cena_obslugi_tona": "265.31",
              "created_at": "2022-11-03 09:03:18.013",
              "updated_at": "2022-11-03 09:03:18.013"
          }
      },
      {
          "id": 121,
          "nazwa": "PPHU Rafpol",
          "kod_pocztowy": "62-872",
          "miejscowosc": "Godziesze Małe",
          "ulica": "Końska Wieś",
          "nr_domu": "42",
          "wojewodztwo_id": "15",
          "telefon": "603166307",
          "email": "biuro@rafpol-opal.pl",
          "geo_n": "51.628391899999997",
          "geo_e": "18.197423499999999",
          "godziny_otwarcia": "8:00-18:00",
          "godziny_otwarcia_sobota": "8:00-16:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "kaliski",
                  "wojewodztwo": "wielkopolskie"
              }
          ],
          "wojewodztwo": {
              "id": 15,
              "nazwa": "wielkopolskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 128,
              "cena_obslugi_tona": "167.44999999999999",
              "created_at": "2022-11-03 08:54:44.673",
              "updated_at": "2022-11-03 08:54:44.673"
          }
      }
  ],
  "zachodniopomorskie": [
      {
          "id": 13,
          "nazwa": "AGROSKŁAD Nowakowski Spółka Jawna",
          "kod_pocztowy": "72-130",
          "miejscowosc": "Maszewo",
          "ulica": "Jedności Narodowej",
          "nr_domu": "22C",
          "wojewodztwo_id": "16",
          "telefon": "506950697",
          "email": "wegiel@agrosklad.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8.00-16.00",
          "godziny_otwarcia_sobota": "8.00-14.00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "goleniowski",
                  "wojewodztwo": "zachodniopomorskie"
              },
              {
                  "powiat": "kamieński",
                  "wojewodztwo": "zachodniopomorskie"
              },
              {
                  "powiat": "policki",
                  "wojewodztwo": "zachodniopomorskie"
              }
          ],
          "wojewodztwo": {
              "id": 16,
              "nazwa": "zachodniopomorskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 24,
              "cena_obslugi_tona": "379.55000000000001",
              "created_at": "2022-09-05 13:23:45.170",
              "updated_at": "2022-09-05 13:23:45.170"
          }
      }
  ],
  "łódzkie": [
      {
          "id": 39,
          "nazwa": "Gminna Spółdzielnia \"Samopomoc Chłopska\" w Pabianicach",
          "kod_pocztowy": "95-070",
          "miejscowosc": "Aleksandrów Łódzki",
          "ulica": "11 go Listopada",
          "nr_domu": "93/97",
          "wojewodztwo_id": "5",
          "telefon": "691941557",
          "email": "plewkam@gspabianice.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:00 - 15:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "brzeziński",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "zgierski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "łódzki wschodni",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 51,
              "cena_obslugi_tona": "169.63999999999999",
              "created_at": "2022-09-15 13:10:24.453",
              "updated_at": "2022-09-15 13:10:24.453"
          }
      },
      {
          "id": 52,
          "nazwa": "PUCHATEK Piotr Kolasa",
          "kod_pocztowy": "98-200",
          "miejscowosc": "Sieradz",
          "ulica": "Uniejowska",
          "nr_domu": "35",
          "wojewodztwo_id": "5",
          "telefon": "697104838",
          "email": "puchatekpiokol@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8.30-15.30",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "sieradzki",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "zduńskowolski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "wieruszowski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "wieluński",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 68,
              "cena_obslugi_tona": "268.87",
              "created_at": "2022-09-20 12:10:28.137",
              "updated_at": "2022-09-20 12:10:28.137"
          }
      },
      {
          "id": 8,
          "nazwa": "Agro-Bud Gajda Spółka Jawna",
          "kod_pocztowy": "99-400",
          "miejscowosc": "Łowicz",
          "ulica": "Dąbkowice Górne",
          "nr_domu": "44",
          "wojewodztwo_id": "5",
          "telefon": "609444906",
          "email": "martagajda@op.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07.00 - 16.00",
          "godziny_otwarcia_sobota": "07.00 - 13.00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "skierniewicki",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "rawski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "łowicki",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 14,
              "cena_obslugi_tona": "221.65000000000001",
              "created_at": "2022-09-01 13:43:27.100",
              "updated_at": "2022-09-01 13:43:27.100"
          }
      },
      {
          "id": 32,
          "nazwa": "PAL-BUD",
          "kod_pocztowy": "99-122",
          "miejscowosc": "Nowy Gaj",
          "ulica": "Nowy Gaj",
          "nr_domu": "37",
          "wojewodztwo_id": "5",
          "telefon": "242544522",
          "email": "palbud@bitserwis.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00-16:00",
          "godziny_otwarcia_sobota": "-",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "poddębicki",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "kutnowski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "łęczycki",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 43,
              "cena_obslugi_tona": "193.87",
              "created_at": "2022-09-13 10:12:38.303",
              "updated_at": "2022-09-13 10:12:38.303"
          }
      },
      {
          "id": 38,
          "nazwa": "Gminna Spółdzielnia \"Samopomoc Chłopska\" w Pabianicach",
          "kod_pocztowy": "95-200",
          "miejscowosc": "Pabianice",
          "ulica": "Torowa",
          "nr_domu": "25",
          "wojewodztwo_id": "5",
          "telefon": "607044885",
          "email": "wegiel@gspabianice.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:00 - 15:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "bełchatowski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "pabianicki",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "łaski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "pajęczański",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 50,
              "cena_obslugi_tona": "97.340000000000003",
              "created_at": "2022-09-15 13:05:56.817",
              "updated_at": "2022-09-15 13:05:56.817"
          }
      },
      {
          "id": 48,
          "nazwa": "Krzysztof Nadajewski Przedsiębiorstwo Produkcyjno-Handlowo-Usługowe \"KAM-BUD\"",
          "kod_pocztowy": "97-360",
          "miejscowosc": "Kamieńsk",
          "ulica": "Wojska Polskiego",
          "nr_domu": "17",
          "wojewodztwo_id": "5",
          "telefon": "501473878",
          "email": "kambud@nadajewski.org",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 -17:00",
          "godziny_otwarcia_sobota": "08:00 -13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "opoczyński",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "tomaszowski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "piotrkowski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "radomszczański",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 62,
              "cena_obslugi_tona": "132.25",
              "created_at": "2022-09-19 14:36:33.783",
              "updated_at": "2022-09-19 14:36:33.783"
          }
      },
      {
          "id": 105,
          "nazwa": "Przedsiębiorstwo Transportowe Handlowo-Usługowe \"GO-TRANS\" Małgorzata Rakocińska",
          "kod_pocztowy": "98-100",
          "miejscowosc": "Łask",
          "ulica": "Krakowska",
          "nr_domu": "3",
          "wojewodztwo_id": "5",
          "telefon": "502375427",
          "email": "gotrans@interia.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00-16:00",
          "godziny_otwarcia_sobota": "07:00-13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "bełchatowski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "pabianicki",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "łaski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "pajęczański",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 112,
              "cena_obslugi_tona": "97.340000000000003",
              "created_at": "2022-10-27 07:22:08.113",
              "updated_at": "2022-10-27 07:22:08.113"
          }
      },
      {
          "id": 110,
          "nazwa": "Firma Handlowo-Usługowa Skład Opału Zofia Piechna,Robert Piechna, Izabela Piechna,Zdzisława Milewska Spółka Jawna",
          "kod_pocztowy": "26-300",
          "miejscowosc": "Opoczno",
          "ulica": "Partyzantów",
          "nr_domu": "7",
          "wojewodztwo_id": "5",
          "telefon": "447552555",
          "email": "ipiechna@interia.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:30-15:30",
          "godziny_otwarcia_sobota": "-",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "opoczyński",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 117,
              "cena_obslugi_tona": "132.25",
              "created_at": "2022-10-27 11:22:26.610",
              "updated_at": "2022-10-27 11:22:26.610"
          }
      },
      {
          "id": 142,
          "nazwa": "Handel Obwoźny Usługi Transportowe i Rolnicze Dębski Mirosław",
          "kod_pocztowy": "96-111",
          "miejscowosc": "Wędrogów",
          "ulica": "Nie dotyczy",
          "nr_domu": "25a",
          "wojewodztwo_id": "5",
          "telefon": "603665146",
          "email": "u.transportowedebski@wp.pl",
          "geo_n": "51.881228100000001",
          "geo_e": "20.402124100000002",
          "godziny_otwarcia": "8:00 - 15:30",
          "godziny_otwarcia_sobota": "8.00 - 11:30",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "skierniewicki",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "rawski",
                  "wojewodztwo": "łódzkie"
              },
              {
                  "powiat": "łowicki",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 150,
              "cena_obslugi_tona": "221.65000000000001",
              "created_at": "2022-12-10 08:46:32.570",
              "updated_at": "2022-12-10 08:46:32.570"
          }
      },
      {
          "id": 141,
          "nazwa": "Przedsiębiorstwo Handlowe \"ROLMAX\" Sylwester Jastrząbek",
          "kod_pocztowy": "98-300",
          "miejscowosc": "Wieluń",
          "ulica": "Traugutta",
          "nr_domu": "51",
          "wojewodztwo_id": "5",
          "telefon": "512220550",
          "email": "rolmax@profipoczta.pl",
          "geo_n": "51.227019900000002",
          "geo_e": "18.552805800000002",
          "godziny_otwarcia": "8.00-16.00",
          "godziny_otwarcia_sobota": "8.00-13.00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "wieluński",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 158,
              "cena_obslugi_tona": "255.94999999999999",
              "created_at": "2023-02-16 08:34:40.570",
              "updated_at": "2023-02-16 08:34:40.570"
          }
      },
      {
          "id": 135,
          "nazwa": "F.H.U. \"PALMAR\" S.C. Violleta Banaszkiewicz Józef Banszkiewicz",
          "kod_pocztowy": "95-045",
          "miejscowosc": "Parzęczew",
          "ulica": "Targowa",
          "nr_domu": "1",
          "wojewodztwo_id": "5",
          "telefon": "427186017",
          "email": "palmarparzeczew@gmail.com",
          "geo_n": "51.946639599999997",
          "geo_e": "19.203438200000001",
          "godziny_otwarcia": "8-16",
          "godziny_otwarcia_sobota": "8-12",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "zgierski",
                  "wojewodztwo": "łódzkie"
              }
          ],
          "wojewodztwo": {
              "id": 5,
              "nazwa": "łódzkie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 143,
              "cena_obslugi_tona": "169.63999999999999",
              "created_at": "2022-11-24 08:40:47.597",
              "updated_at": "2022-11-24 08:40:47.597"
          }
      }
  ],
  "śląskie": [
      {
          "id": 68,
          "nazwa": "Składy Opałowe STOKŁOSA Sp. z o.o. Sp. K.",
          "kod_pocztowy": "44-336",
          "miejscowosc": "Jastrzębie-Zdrój",
          "ulica": "Niepodległości",
          "nr_domu": "8c",
          "wojewodztwo_id": "12",
          "telefon": "789296300",
          "email": "kdwbziestoklosa@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 16:00",
          "godziny_otwarcia_sobota": "07:00 - 11:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "raciborski",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "wodzisławski",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Jastrzębie-Zdrój",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 75,
              "cena_obslugi_tona": "148.44",
              "created_at": "2022-09-22 10:43:57.160",
              "updated_at": "2022-09-22 10:43:57.160"
          }
      },
      {
          "id": 69,
          "nazwa": "JAPI-BUD DUDA SPÓŁKA JAWNA",
          "kod_pocztowy": "43-200",
          "miejscowosc": "Pszczyna",
          "ulica": "Katowicka",
          "nr_domu": "106",
          "wojewodztwo_id": "12",
          "telefon": "530730413",
          "email": "japibud.kdw@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 16:00",
          "godziny_otwarcia_sobota": "07:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "pszczyński",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "mikołowski",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 76,
              "cena_obslugi_tona": "54.609999999999999",
              "created_at": "2022-09-22 11:26:38.357",
              "updated_at": "2022-09-22 11:26:38.357"
          }
      },
      {
          "id": 72,
          "nazwa": "Firma Handlowo Transportowa Urszula Gawlik",
          "kod_pocztowy": "44-230",
          "miejscowosc": "Bełk",
          "ulica": "Karola Miarki",
          "nr_domu": "-",
          "wojewodztwo_id": "12",
          "telefon": "509311977",
          "email": "kdw.gawlik@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 16:00",
          "godziny_otwarcia_sobota": "07:00 - 12:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "rybnicki",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Rybnik",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Żory",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 79,
              "cena_obslugi_tona": "79.810000000000002",
              "created_at": "2022-09-26 12:45:43.610",
              "updated_at": "2022-09-26 12:45:43.610"
          }
      },
      {
          "id": 49,
          "nazwa": "Składy Opałowe Stokłosa Sp. z o.o.",
          "kod_pocztowy": "41-700",
          "miejscowosc": "Ruda Śląska",
          "ulica": "Kokotek",
          "nr_domu": "18A",
          "wojewodztwo_id": "12",
          "telefon": "322515290",
          "email": "kdwstoklosa@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7.00 - 15.00",
          "godziny_otwarcia_sobota": "7.00 - 11.00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "Bytom",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Chorzów",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Piekary Śląskie",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Ruda Śląska",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Siemianowice Śląskie",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Świętochłowice",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Zabrze",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 63,
              "cena_obslugi_tona": "108.58",
              "created_at": "2022-09-20 11:19:47.853",
              "updated_at": "2022-09-20 11:19:47.853"
          }
      },
      {
          "id": 74,
          "nazwa": "Przeds.Handlowo-Usługowe Skład Opałowo-Bud. s.c. Kuźnik, Zyzański",
          "kod_pocztowy": "34-300",
          "miejscowosc": "Żywiec",
          "ulica": "Isep",
          "nr_domu": "75",
          "wojewodztwo_id": "12",
          "telefon": "338616400",
          "email": "romek502@onet.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 16:00",
          "godziny_otwarcia_sobota": "07:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "żywiecki",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 81,
              "cena_obslugi_tona": "107.34999999999999",
              "created_at": "2022-09-26 14:28:47.187",
              "updated_at": "2022-09-26 14:28:47.187"
          }
      },
      {
          "id": 76,
          "nazwa": "Waleria Goch HANDEL-EXPORT-IMPORT Wyrobów Przemysłowych i Rolno-Spożywczych",
          "kod_pocztowy": "43-300",
          "miejscowosc": "Bielsko-Biała",
          "ulica": "Piekarska",
          "nr_domu": "15",
          "wojewodztwo_id": "12",
          "telefon": "515805705",
          "email": "kdwbielsko@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "od 7:00 do 15:00",
          "godziny_otwarcia_sobota": "nieczynne",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "bielski",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 85,
              "cena_obslugi_tona": "69.370000000000005",
              "created_at": "2022-09-27 10:01:29.487",
              "updated_at": "2022-09-27 10:01:29.487"
          }
      },
      {
          "id": 42,
          "nazwa": "Węglosped Karol Gwóźdź i Współnicy Spółka Jawna",
          "kod_pocztowy": "43-140",
          "miejscowosc": "Lędziny",
          "ulica": "Lędzińska",
          "nr_domu": "287",
          "wojewodztwo_id": "12",
          "telefon": "690174656",
          "email": "weglosped@poczta.internetdsl.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8:00-20:00",
          "godziny_otwarcia_sobota": "8:00-14:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "bieruńsko-lędziński",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Jaworzno",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Katowice",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Mysłowice",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Sosnowiec",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Tychy",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 60,
              "cena_obslugi_tona": "139.13999999999999",
              "created_at": "2022-09-19 13:47:31.097",
              "updated_at": "2022-09-19 13:47:31.097"
          }
      },
      {
          "id": 82,
          "nazwa": "CYROŃ Spółka z ograniczonąodpowiedzialnością",
          "kod_pocztowy": "43-246",
          "miejscowosc": "Zabłocie",
          "ulica": "Bielska",
          "nr_domu": "54",
          "wojewodztwo_id": "12",
          "telefon": "781877535",
          "email": "kdw@cyron.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "cieszyński",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 89,
              "cena_obslugi_tona": "64.599999999999994",
              "created_at": "2022-09-29 12:31:09.710",
              "updated_at": "2022-09-29 12:31:09.710"
          }
      },
      {
          "id": 83,
          "nazwa": "REMAT Cichoń S.J.",
          "kod_pocztowy": "42-580",
          "miejscowosc": "Wojkowice",
          "ulica": "Jana Długosza",
          "nr_domu": "11",
          "wojewodztwo_id": "12",
          "telefon": "600386054",
          "email": "rematcichon@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8.00-16.00",
          "godziny_otwarcia_sobota": "08.00-12.00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "myszkowski",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "będziński",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "zawierciański",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 90,
              "cena_obslugi_tona": "109.52",
              "created_at": "2022-10-03 15:38:39.680",
              "updated_at": "2022-10-03 15:38:39.680"
          }
      },
      {
          "id": 34,
          "nazwa": "Grażyna Staniowska Firma \"AN-MAX\"",
          "kod_pocztowy": "42-202",
          "miejscowosc": "Częstochowa",
          "ulica": "Zaciszańska",
          "nr_domu": "51",
          "wojewodztwo_id": "12",
          "telefon": "602669055",
          "email": "an-max.gs@tlen.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 16:00",
          "godziny_otwarcia_sobota": "Nieczynne",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "częstochowski",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Częstochowa",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 46,
              "cena_obslugi_tona": "181.78999999999999",
              "created_at": "2022-09-14 12:10:21.290",
              "updated_at": "2022-09-14 12:10:21.290"
          }
      },
      {
          "id": 64,
          "nazwa": "Cegiełka-Bis S.C. Andrzej i Roman Dygudaj",
          "kod_pocztowy": "42-140",
          "miejscowosc": "Panki, Pacanów",
          "ulica": "---",
          "nr_domu": "25A",
          "wojewodztwo_id": "12",
          "telefon": "343179586",
          "email": "pacanow@cegielka.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:00 - 15:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "kłobucki",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "lubliniecki",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 71,
              "cena_obslugi_tona": "123.0",
              "created_at": "2022-09-20 12:52:37.937",
              "updated_at": "2022-09-20 12:52:37.937"
          }
      },
      {
          "id": 100,
          "nazwa": "Starpeks Starzycki&Stożek",
          "kod_pocztowy": "41-219",
          "miejscowosc": "Sosnowiec",
          "ulica": "ul. Karola Szymanowskiego",
          "nr_domu": "93",
          "wojewodztwo_id": "12",
          "telefon": "322921062",
          "email": "sprzedaz@starpex-spk.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:00-16:00",
          "godziny_otwarcia_sobota": "-",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "Sosnowiec",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 107,
              "cena_obslugi_tona": "139.13999999999999",
              "created_at": "2022-10-26 10:31:17.407",
              "updated_at": "2022-10-26 10:31:17.407"
          }
      },
      {
          "id": 103,
          "nazwa": "Usługi Transportowe Skład Opału S.C. Mularczyk Krystian, Mularczyk Irena",
          "kod_pocztowy": "41-936",
          "miejscowosc": "Bytom",
          "ulica": "Przyjemna",
          "nr_domu": "26",
          "wojewodztwo_id": "12",
          "telefon": "322867659",
          "email": "skladmularczyk@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "Bytom",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 110,
              "cena_obslugi_tona": "108.58",
              "created_at": "2022-10-27 07:05:24.223",
              "updated_at": "2022-10-27 07:05:24.223"
          }
      },
      {
          "id": 106,
          "nazwa": "Tomex Tomasz Koczy",
          "kod_pocztowy": "44-300",
          "miejscowosc": "Wodzisław Śląski",
          "ulica": "Marklowicka",
          "nr_domu": "34 c",
          "wojewodztwo_id": "12",
          "telefon": "796253949",
          "email": "tomex_tk@interia.pl",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7.00-16.00",
          "godziny_otwarcia_sobota": "7.30-13.00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "wodzisławski",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 113,
              "cena_obslugi_tona": "148.44",
              "created_at": "2022-10-27 07:55:10.250",
              "updated_at": "2022-10-27 07:55:10.250"
          }
      },
      {
          "id": 107,
          "nazwa": "JB Sebastian Jarzombek",
          "kod_pocztowy": "42-425",
          "miejscowosc": "Siamoszyce",
          "ulica": "Słoneczna",
          "nr_domu": "19",
          "wojewodztwo_id": "12",
          "telefon": "692362692",
          "email": "jbtaniopal@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:00 - 17:00",
          "godziny_otwarcia_sobota": "07:00 - 13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "myszkowski",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "będziński",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "zawierciański",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Dąbrowa Górnicza",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 114,
              "cena_obslugi_tona": "109.52",
              "created_at": "2022-10-27 08:00:32.863",
              "updated_at": "2022-10-27 08:00:32.863"
          }
      },
      {
          "id": 98,
          "nazwa": "„ROMAX” S.C. Sylwia i Andrzej Rogon",
          "kod_pocztowy": "44-153",
          "miejscowosc": "Sośnicowice",
          "ulica": "Kozielska",
          "nr_domu": "22",
          "wojewodztwo_id": "12",
          "telefon": "572214585",
          "email": "rogonromax@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00 - 15:00",
          "godziny_otwarcia_sobota": "09:00 - 12:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "gliwicki",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "tarnogórski",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Gliwice",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 170,
              "cena_obslugi_tona": "180.44999999999999",
              "created_at": "2023-02-23 07:17:35.710",
              "updated_at": "2023-02-23 07:17:35.710"
          }
      },
      {
          "id": 115,
          "nazwa": "WEGMAR Mariola Kolańczak - Stokłosa",
          "kod_pocztowy": "43-188",
          "miejscowosc": "Orzesze",
          "ulica": "Towarowa",
          "nr_domu": "5",
          "wojewodztwo_id": "12",
          "telefon": "604060134",
          "email": "kdworzesze@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "08:00-16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "pszczyński",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "mikołowski",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 122,
              "cena_obslugi_tona": "54.490000000000002",
              "created_at": "2022-11-02 09:42:33.490",
              "updated_at": "2022-11-02 09:42:33.490"
          }
      },
      {
          "id": 131,
          "nazwa": "Transport Towarowy i Handel Henryk Muskalski",
          "kod_pocztowy": "42-233",
          "miejscowosc": "Mykanów - Wola Hankowska",
          "ulica": "Akacjowa",
          "nr_domu": "36",
          "wojewodztwo_id": "12",
          "telefon": "797217217",
          "email": "muskalska@onet.eu",
          "geo_n": "50.876713600000002",
          "geo_e": "19.091352499999999",
          "godziny_otwarcia": "8:00-15:00",
          "godziny_otwarcia_sobota": "-",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "częstochowski",
                  "wojewodztwo": "śląskie"
              },
              {
                  "powiat": "Częstochowa",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 140,
              "cena_obslugi_tona": "181.78999999999999",
              "created_at": "2022-11-21 06:01:10.663",
              "updated_at": "2022-11-21 06:01:10.663"
          }
      },
      {
          "id": 128,
          "nazwa": "PHU Waldemar Glos",
          "kod_pocztowy": "43-394",
          "miejscowosc": "Rudzica",
          "ulica": "Rudzica",
          "nr_domu": "478",
          "wojewodztwo_id": "12",
          "telefon": "663154974",
          "email": "kdwrudzica@gmail.com",
          "geo_n": "49.855455300000003",
          "geo_e": "18.886733",
          "godziny_otwarcia": "8-16",
          "godziny_otwarcia_sobota": "Nieczynne",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "bielski",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 134,
              "cena_obslugi_tona": "69.370000000000005",
              "created_at": "2022-11-09 10:41:49.847",
              "updated_at": "2022-11-09 10:41:49.847"
          }
      },
      {
          "id": 134,
          "nazwa": "Przedsiębiorstwo Handlowo Usługowe Wiewióra Krzysztof Sp. z o.o.",
          "kod_pocztowy": "34-321",
          "miejscowosc": "Łękawica",
          "ulica": "Krakowska",
          "nr_domu": "1 A",
          "wojewodztwo_id": "12",
          "telefon": "605764899",
          "email": "phu.wiewiora@wp.pl",
          "geo_n": "49.709610900000001",
          "geo_e": "19.281623199999999",
          "godziny_otwarcia": "8:00-16:00",
          "godziny_otwarcia_sobota": "8:00-13:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "żywiecki",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 142,
              "cena_obslugi_tona": "107.34999999999999",
              "created_at": "2022-11-22 12:01:11.987",
              "updated_at": "2022-11-22 12:01:11.987"
          }
      },
      {
          "id": 127,
          "nazwa": "PHU Waldemar Glos",
          "kod_pocztowy": "43-430",
          "miejscowosc": "Pierściec",
          "ulica": "Ks. H . Sobeckiego",
          "nr_domu": "4",
          "wojewodztwo_id": "12",
          "telefon": "516410615",
          "email": "kdwpiersciec@gmail.com",
          "geo_n": "49.842397900000002",
          "geo_e": "18.821271299999999",
          "godziny_otwarcia": "8:00-16:00",
          "godziny_otwarcia_sobota": "Nieczynne",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "cieszyński",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 135,
              "cena_obslugi_tona": "64.599999999999994",
              "created_at": "2022-11-09 10:50:46.467",
              "updated_at": "2022-11-09 10:50:46.467"
          }
      },
      {
          "id": 143,
          "nazwa": "Przedsiębiorstwo Handlowo Usługowe \"BEMAR\" M. Bensz i Wspólnicy Sp. J.",
          "kod_pocztowy": "42-610",
          "miejscowosc": "Miasteczko Śląskie",
          "ulica": "Przyjaźni",
          "nr_domu": "2A",
          "wojewodztwo_id": "12",
          "telefon": "607933966",
          "email": "pati@bemartransport.pl",
          "geo_n": "50.4979181",
          "geo_e": "18.943284899999998",
          "godziny_otwarcia": "8-16",
          "godziny_otwarcia_sobota": "8-11",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "tarnogórski",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 151,
              "cena_obslugi_tona": "211.56",
              "created_at": "2022-12-21 13:04:20.353",
              "updated_at": "2022-12-21 13:04:20.353"
          }
      },
      {
          "id": 123,
          "nazwa": "PPUH AMS TOMICZEK s.c.",
          "kod_pocztowy": "44-295",
          "miejscowosc": "Sumina",
          "ulica": "Polna",
          "nr_domu": "2",
          "wojewodztwo_id": "12",
          "telefon": "535103400",
          "email": "kdw.tomiczek@gmail.com",
          "geo_n": "50.120841400000003",
          "geo_e": "18.408981900000001",
          "godziny_otwarcia": "7:00 - 17:00",
          "godziny_otwarcia_sobota": "7:00 - 14:00",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "rybnicki",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 129,
              "cena_obslugi_tona": "79.810000000000002",
              "created_at": "2022-11-03 09:03:07.730",
              "updated_at": "2022-11-03 09:03:07.730"
          }
      },
      {
          "id": 138,
          "nazwa": "JUST-LUK Łukasz Grabinski",
          "kod_pocztowy": "42-700",
          "miejscowosc": "Lubliniec",
          "ulica": "Klonowa",
          "nr_domu": "42a",
          "wojewodztwo_id": "12",
          "telefon": "795713713",
          "email": "opalgrabinski@gmail.com",
          "geo_n": "50.676834900000003",
          "geo_e": "18.706133000000001",
          "godziny_otwarcia": "07:00 - 15:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "lubliniecki",
                  "wojewodztwo": "śląskie"
              }
          ],
          "wojewodztwo": {
              "id": 12,
              "nazwa": "śląskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 146,
              "cena_obslugi_tona": "123.0",
              "created_at": "2022-11-25 10:21:58.147",
              "updated_at": "2022-11-25 10:21:58.147"
          }
      }
  ],
  "świętokrzyskie": [
      {
          "id": 24,
          "nazwa": "DIAMANDS Ilona Ruchomska",
          "kod_pocztowy": "27-400",
          "miejscowosc": "Ostrowiec Świętokrzyski",
          "ulica": "Samsonowicza",
          "nr_domu": "19/A",
          "wojewodztwo_id": "13",
          "telefon": "603630172",
          "email": "kdw.diamands@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7.30 - 15.30",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "sandomierski",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "opatowski",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "staszowski",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "ostrowiecki",
                  "wojewodztwo": "świętokrzyskie"
              }
          ],
          "wojewodztwo": {
              "id": 13,
              "nazwa": "świętokrzyskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 35,
              "cena_obslugi_tona": "110.45",
              "created_at": "2022-09-08 10:43:35.120",
              "updated_at": "2022-09-08 10:43:35.120"
          }
      },
      {
          "id": 28,
          "nazwa": "NEW VISION Ruchomski Rafał",
          "kod_pocztowy": "26-006",
          "miejscowosc": "Nowa Słupia",
          "ulica": "Staszica",
          "nr_domu": "1118",
          "wojewodztwo_id": "13",
          "telefon": "667201020",
          "email": "kdw.newvision@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7.30 - 15.30",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "starachowicki",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "kielecki",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "skarżyski",
                  "wojewodztwo": "świętokrzyskie"
              }
          ],
          "wojewodztwo": {
              "id": 13,
              "nazwa": "świętokrzyskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 41,
              "cena_obslugi_tona": "102.14",
              "created_at": "2022-09-13 08:33:29.690",
              "updated_at": "2022-09-13 08:33:29.690"
          }
      },
      {
          "id": 14,
          "nazwa": "Przedsiębiorstwo Handlowo Usługowe Grzegorz Sarna",
          "kod_pocztowy": "28-100",
          "miejscowosc": "Busko-Zdrój",
          "ulica": "Batalionów Chłopskich",
          "nr_domu": "3",
          "wojewodztwo_id": "13",
          "telefon": "697573702",
          "email": "biurosarna@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "7:00 - 17:00",
          "godziny_otwarcia_sobota": "7:00 - 13:00",
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": "0.0",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "pińczowski",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "buski",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "kazimierski",
                  "wojewodztwo": "świętokrzyskie"
              }
          ],
          "wojewodztwo": {
              "id": 13,
              "nazwa": "świętokrzyskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 25,
              "cena_obslugi_tona": "147.59999999999999",
              "created_at": "2022-09-05 13:24:03.370",
              "updated_at": "2022-09-05 13:24:03.370"
          }
      },
      {
          "id": 7,
          "nazwa": "KARBONEX sp. z o.o.",
          "kod_pocztowy": "28-300",
          "miejscowosc": "Jędrzejów",
          "ulica": "Partyzantów",
          "nr_domu": "73",
          "wojewodztwo_id": "13",
          "telefon": "663770509",
          "email": "karbonexmj@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "8-16",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "koszt_dostawy_do_klienta_kmt": null,
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "konecki",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "jędrzejowski",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "włoszczowski",
                  "wojewodztwo": "świętokrzyskie"
              }
          ],
          "wojewodztwo": {
              "id": 13,
              "nazwa": "świętokrzyskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 17,
              "cena_obslugi_tona": "169.22",
              "created_at": "2022-09-01 13:44:30.387",
              "updated_at": "2022-09-01 13:44:30.387"
          }
      },
      {
          "id": 124,
          "nazwa": "Firma Handlowo Usługowa Pagieła Teresa Pagieła",
          "kod_pocztowy": "28-500",
          "miejscowosc": "Kazimierza Wielka",
          "ulica": "Kolejowa",
          "nr_domu": "136",
          "wojewodztwo_id": "13",
          "telefon": "728377946",
          "email": "kdw.pagiela@gmail.com",
          "geo_n": null,
          "geo_e": null,
          "godziny_otwarcia": "07:30 - 16:30",
          "godziny_otwarcia_sobota": "07:30 - 13:30",
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "obs_powiaty": [
              {
                  "powiat": "pińczowski",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "buski",
                  "wojewodztwo": "świętokrzyskie"
              },
              {
                  "powiat": "kazimierski",
                  "wojewodztwo": "świętokrzyskie"
              }
          ],
          "wojewodztwo": {
              "id": 13,
              "nazwa": "świętokrzyskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 131,
              "cena_obslugi_tona": "147.59999999999999",
              "created_at": "2022-11-03 10:49:06.740",
              "updated_at": "2022-11-03 10:49:06.740"
          }
      },
      {
          "id": 133,
          "nazwa": "PPHU RADEX Krystian Tarnowski",
          "kod_pocztowy": "29-100",
          "miejscowosc": "Włoszczowa",
          "ulica": "Jędrzejowska",
          "nr_domu": "79A",
          "wojewodztwo_id": "13",
          "telefon": "500115911",
          "email": "krystiantarnowski@vp.pl",
          "geo_n": "50.846919999999997",
          "geo_e": "19.995774000000001",
          "godziny_otwarcia": "9:00 - 16:00",
          "godziny_otwarcia_sobota": null,
          "aktywny": "1",
          "obsluga_ogolnopolska": false,
          "informacja_marketingowa_1": null,
          "informacja_marketingowa_2": null,
          "adres_www": null,
          "obs_powiaty": [
              {
                  "powiat": "włoszczowski",
                  "wojewodztwo": "świętokrzyskie"
              }
          ],
          "wojewodztwo": {
              "id": 13,
              "nazwa": "świętokrzyskie",
              "czyAktywne": 1
          },
          "cena_transportu": {
              "id": 141,
              "cena_obslugi_tona": "169.22",
              "created_at": "2022-11-21 09:16:18.427",
              "updated_at": "2022-11-21 09:16:18.427"
          }
      }
  ]
}
