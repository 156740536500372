import React from "react";
import { kdwList } from "./kdwList";
import { services } from "./services";
import AdSense from "react-adsense";
import { AD_CLIENT, AD_SLOT } from "../../constant";
import BaseTooltip from "../BaseTooltip/BaseTooltip";

const flattenKdw = Object.values(kdwList).flatMap(item => item) as any[];
const sortByPrice = flattenKdw.sort(
  (a, b) =>
    Number(a.cena_transportu.cena_obslugi_tona) -
    Number(b.cena_transportu.cena_obslugi_tona)
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const KDW = () => {
  return (
    <>
      <div className="bg-white lg:min-w-0 lg:flex-1">
        {sortByPrice.map((item, index) => {
          const service = services.find(s => s.id === item.id);

          return (
            <React.Fragment key={index}>
              {Boolean(Number(index) % 4 === 0) && index !== 0 && (
                <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
                  <AdSense.Google
                    client={AD_CLIENT}
                    slot={AD_SLOT}
                    style={{ display: "block" }}
                    format="auto"
                    responsive="true"
                    layoutKey="-gw-1+2a-9x+5c"
                  />
                </div>
              )}
              <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="max-w-none mx-auto">
                  <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
                    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                      <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                        <div className="ml-4 mt-4">
                          <div className="flex-col sm:flex-row flex items-start sm:items-end">
                            <div className="">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {item.nazwa}
                              </h3>
                              <div className="flex items-center text-sm text-gray-500 m2-">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  x-description="Heroicon name: solid/location-marker"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                                {item.ulica} {item.nr_domu}
                                <br />
                                {item.kosz_pocztowy} {item.miejscowosc} <br />
                                woj. {item.wojewodztwo.nazwa}
                              </div>
                              <div className="flex items-center text-sm text-gray-500">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  strokeWidth="{1.5}"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                  ></path>
                                </svg>
                                pon - pią {item.godziny_otwarcia}
                                <br />
                                {item.godziny_otwarcia_sobota ? (
                                  <>sob {item.godziny_otwarcia_sobota}</>
                                ) : null}
                              </div>
                            </div>

                            {item.telefon && (
                              <a
                                href={`tel:${item.telefon}`}
                                className="relative inline-flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex sm:hidden"
                              >
                                <svg
                                  className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                  x-description="Heroicon name: solid/phone"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                                </svg>
                                <span>{item.telefon}</span>
                              </a>
                            )}
                            {item.email && (
                              <a
                                href={`mailto:${item.email}`}
                                className="relative inline-flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex sm:hidden"
                              >
                                <svg
                                  className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                  x-description="Heroicon name: solid/mail"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                </svg>
                                <span>{item.email}</span>
                              </a>
                            )}
                          </div>

                          <h3 className="text-md leading-6 font-medium text-gray-900 mt-5">
                            Obsługiwane powiaty
                          </h3>

                          <div className="flex flex-wrap items-center mt-2">
                            {item.obs_powiaty.map(item => {
                              return (
                                <span className="bg-green-100 text-green-800 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2 mb-2">
                                  {item.powiat}
                                </span>
                              );
                            })}
                          </div>
                        </div>

                        <div className="ml-4 sm:mt-4 flex-shrink-0 flex flex-col w-full sm:w-auto">
                          <a
                            href={`tel:${item.telefon}`}
                            className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 hidden sm:flex"
                          >
                            <svg
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                              x-description="Heroicon name: solid/phone"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                            </svg>
                            <span>{item.telefon}</span>
                          </a>
                          <a
                            href={`mailto:${item.email}`}
                            className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hidden sm:flex"
                          >
                            <svg
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                              x-description="Heroicon name: solid/mail"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                            </svg>
                            <span>{item.email}</span>
                          </a>

                          <div className="sm:px-4 py-5 bg-white text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <dt className="text-sm font-medium text-gray-500 truncate">
                              Cena za dostawę do KDW
                            </dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 mb-0">
                              {Number(
                                item.cena_transportu.cena_obslugi_tona
                              ).toFixed(2)}
                              zł/t
                            </dd>
                          </div>
                          {item.aktualny_limit_miesieczny ? (
                            <div className="sm:px-4 py-5 bg-white text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 relative">
                              <BaseTooltip
                                content={
                                  <>
                                    Każde KDW w danym miesiąca może obsłużyć
                                    daną liczbe ton. Gdy limit zejdzie do zera
                                    to dane KDW nie będzie dostępne.
                                  </>
                                }
                                className="flex flex-col"
                              >
                                <dt className="text-sm font-medium text-gray-500 truncate">
                                  To KDW może odebrać jeszcze
                                </dt>
                                <dd className="mt-1 text-3xl font-semibold text-gray-900 mb-0 ">
                                  {Number(
                                    item.aktualny_limit_miesieczny
                                      .pozostaly_limit
                                  ).toFixed(0)}
                                  t z{" "}
                                  {Number(
                                    item.aktualny_limit_miesieczny.limit
                                  ).toFixed(0)}
                                  t
                                </dd>
                                <dt className="text-sm font-medium text-gray-500 truncate sm:text-right">
                                  w tym miesiącu
                                </dt>
                              </BaseTooltip>
                            </div>
                          ) : (
                            <div className="sm:px-4 py-5 bg-white text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <dt className="text-sm font-medium text-gray-500 truncate">
                                PGG usunęło informacje o limitach z dniem 7.10
                              </dt>
                            </div>
                          )}
                          {item.aktualny_limit_miesieczny && (
                            <p className="text-md font-medium text-right px-5">
                              zaaktulizowano: <br />
                              {new Date(
                                item.aktualny_limit_miesieczny.updated_at
                              ).toLocaleString("pl-PL")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <h3 className="px-4 sm:px-6 text-md leading-6 font-medium text-gray-900 mt-5">
                      Dodatkowe usługi:
                    </h3>

                    <ul role="list" className="divide-y divide-gray-200">
                      {service ? (
                        service.items.map(s => {
                          return (
                            <li>
                              <div className="py-2 sm:px-6">
                                <div className="flex flex-col flex-end">
                                  <div className="flex items-center justify-between pr-8">
                                    <div className="text-md font-medium text-indigo-600">
                                      {s.label}
                                    </div>
                                    <div className="mt-2 flex flex-col justify-center items-end">
                                      <div className="ml-2 flex items-center text-sm text-gray-500">
                                        <span
                                          className={classNames(
                                            s.value
                                              ? "bg-green-100 text-green-800"
                                              : "bg-red-100 text-red-800",
                                            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                                          )}
                                        >
                                          {s.value ? "TAK" : "NIE/brak danych"}
                                        </span>
                                      </div>

                                      <div className="ml-2 flex items-center text-sm text-gray-500">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                          ></path>
                                        </svg>
                                        {s.value}
                                      </div>
                                      {s.label.includes("Dostawa") && (
                                        <>
                                          <br />
                                          <p className="text-xs">
                                            transport jest często liczony w dwie
                                            strony
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <>
                          <p className="text-md font-medium text-indigo-600">
                            Brak informacji o dodatkowych usługach będą one
                            sukcesywnie uzupełniane.
                          </p>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
        <p className="text-xs text-center">
          Lista dodatkowych usług ma charakter informacyjny i nie stanowi oferty
          handlowej w rozumieniu art. 66 §1 Kodeksu Cywilnego
        </p>
      </div>
    </>
  );
};
