import React from "react";

import {KDW} from "../components/KDW/KDW";
import BaseLayout from "../layout/BaseLayout";

const Index: React.FC = () => {
  return (
    <BaseLayout
      header="Cennik i usługi Kwalfikowanych dostawców węgla"
      headerSeo="Cennik i usługi Kwalfikowanych dostawców węgla"
      description="Kwalifikowani Dostawcy Węgla, wykonują usługę transportu i wydania klientowi węgla kupionego bezpośrednio w e-sklepie PGG S.A. Niektóre z sortymentów takie jak miał węglowy może być dostarczony tylko w taki sposób."
    >
      <KDW />
    </BaseLayout>
  );
};

export default Index;
